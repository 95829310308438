import { useState } from "react";
import { MessangerIcon } from "./messangerIcon";



import "./messenger.scss";

export const MessengerPanel = () => {

    const [wasMessengerHovered, SetWasMessengerHovered] = useState(false);

    return (<>
    
        <div className='main-page__messenger' onMouseLeave={() => SetWasMessengerHovered(true)} onClick={()=> {
            const url = "https://m.me/61558293877112";
            window.open(url, '_blank');
        }}>
            <MessangerIcon/>
        </div>

        <div className={`main-page__messanger-text ${wasMessengerHovered ? 'main-page__messanger-text--inactive' : ''}`}>
            <span>
                👋 Jak możemy ci pomóc?
            </span>
        </div>
    </>);
}