import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../../utilities/rest/apiClient";

import { useTranslation } from "react-i18next";

import { Logo } from "../../navbar/logo/logo";
import { PasswordLabel } from "./passwordLabel/PasswordLabel";
import { Unauthorized } from "../../../utilities/rest/errors";
import { checkNumberOfCharacters, checkCapitalization, checkNonCapitlization, checkDigits, getInputFieldState } from "../shared/sharedFunctions"
import { Alert } from "../shared/sharedInterfaces";
import { CheckBox } from "./CheckBox/CheckBox";

import "../login/login.scss";
import { AuthContext } from "../authContext/AuthContextProvider";
import { usePageTracking } from "../../../hooks/analytics/usePageTracking";


export const SignUp = () => {
    usePageTracking("signup");
    const maxPasswordLength = 100;
    const maxEmailLength = 100;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [validPasswordConfirmation, setValidPasswordConfirmation] = useState(false);

    const [eightSymbolsChecked, setEightSymbolsChecked] = useState(false);
    const [bigLetterChecked, setBigLetterChecked] = useState(false);
    const [digitChecked, setDigitChecked] = useState(false);
    const [smallLetterChecked, setSmallLetterChecked] = useState(false);

    const [formChecked, setFormChecked] = useState(false);

    const [signupError, setSignupError] = useState(false);
    const [signupErrorMessage, setSignupErrorMessage] = useState("");

    const authContext = useContext(AuthContext);

    const alerts: Alert[] = [
        { text: t("signUp.passwordRequirements.min8signs"), status: eightSymbolsChecked },
        { text: t("signUp.passwordRequirements.min1capitalLetter"), status: bigLetterChecked },
        { text: t("signUp.passwordRequirements.min1nonCapitalLetter"), status: smallLetterChecked },
        { text: t("signUp.passwordRequirements.min1digit"), status: digitChecked },
    ];
      
    const handleSignup = (e: FormEvent) => {
        e.preventDefault();

        setSignupError(false);
        setSignupErrorMessage("");

        if(validPassword && validEmail && validPasswordConfirmation && formChecked) {
            
            const data = { email: email, password: password, passwordConfirm: confirmPassword};

            post<any, any>(`users/quickSignup/pl`, data)
            .then(() => {
                authContext.setLoggedIn(true);
                navigate('/userPanel');
            })
            .catch((error) => {
                setEmail("");
                setValidEmail(false);
                setSignupError(true);
                authContext.setLoggedIn(false);

                if(error instanceof Unauthorized){
                    const message = t("signUp.error.accountAlreadyExists");
                    setSignupErrorMessage(message);
                } else {
                    const message = t("signUp.error.internalError");
                    setSignupErrorMessage(message);

                    setPassword("");
                    setValidPassword(false);

                    setConfirmPassword("");
                    setValidPasswordConfirmation(false);

                    setFormChecked(false);
                }
            });
        }
    };

    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_email = e.target.value;
      setEmail(new_email);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };
  
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_password = e.target.value;
      setPassword(new_password);

      const digitsCheck = checkDigits(new_password, 1);
      const capitalizationCheck = checkCapitalization(new_password, 1);
      const numberOfCharactersCheck = checkNumberOfCharacters(new_password, 8);
      const nonCapitalizationCheck = checkNonCapitlization(new_password, 1);

      setEightSymbolsChecked(numberOfCharactersCheck);
      setBigLetterChecked(capitalizationCheck);
      setDigitChecked(digitsCheck);
      setSmallLetterChecked(nonCapitalizationCheck);

      if((confirmPassword === new_password) && (new_password.length !== 0)) setValidPasswordConfirmation(true)
      else setValidPasswordConfirmation(false);

      if(
        digitsCheck && 
        capitalizationCheck && 
        numberOfCharactersCheck && 
        nonCapitalizationCheck
      ) 
        setValidPassword(true);
      else
        setValidPassword(false);
    };


    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_passwordConfirmation = e.target.value; 
        
        setConfirmPassword(new_passwordConfirmation);

        if((new_passwordConfirmation === password) && (password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
    }

    const handleSubmitButtonStyle = () => {
        let buttonClass = "button";
        buttonClass += (validPassword && validEmail && validPasswordConfirmation && formChecked) ? "--active" : "--inactive";
        buttonClass += " button";
        return buttonClass;
    }

    const handlePasswordInputStyle = (validElement: boolean, elementTextLength: number) => {
        const base = "resetPassword-panel__form__password-input-section";
        let passwordInputClass = base + " " + base + "--short";

        if(elementTextLength !== undefined)
            if( (!validElement) && (elementTextLength !== 0) )
                passwordInputClass +=  " " + base + "--validation-active";

        return passwordInputClass;
    }

    const handleEmailInputStyle = () => {
        const base = "resetPassword-panel__form__email-input-section";
        let finalClass = base + " " + base + "--tall ";

        if(signupError) finalClass += " resetPassword-panel__form__password-input-section--validation-active";

        return finalClass;
    }

    const handleMainComponentClass = () => {
        const base = "resetPassword-panel";
        let finalClass = base;
        
        const passwordClosed = ( (password.length === 0) || validPassword);
        const passwordConfirmClosed = ( (confirmPassword.length === 0) || validPasswordConfirmation);

        if(passwordClosed && passwordConfirmClosed)
            finalClass += ` ${base}--everything-closed`;
        else if((!passwordClosed) && passwordConfirmClosed)
            finalClass += ` ${base}--pass-active`;
        else if(passwordClosed && (!passwordConfirmClosed))
            finalClass += ` ${base}--pass-confirm-active`;
        else
            finalClass += ` ${base}--double-pass-active`;

        return finalClass;
    }

    return (
        <div className={handleMainComponentClass()}>
            <div className="resetPassword-panel__form">
                <div className="login-panel__form__logo-wrapper" onClick={() => navigate('/')}>
                    <Logo/>
                </div>
                <div className="resetPassword-panel__form__headline">
                    <span>{t("signUp.signUpToTheWebsite")}</span>
                </div>
                <div className="resetPassword-panel__form__sign-up">
                    <span>{t("signUp.alreadyHaveAnAccount")}? </span>
                    <a href="/login">{t("signUp.login")}</a>
                </div>

                <form onSubmit={handleSignup}>
                    <div className={handleEmailInputStyle()}>
                        <label htmlFor="username">{t("login.emailAddress")}</label>
                        <input
                            className={getInputFieldState(validEmail, email.length)}
                            type="text"
                            id="username"
                            maxLength={maxEmailLength}
                            value={email}
                            onChange={handleUsernameChange}
                        />

                        { signupError ? <label className="resetPassword-panel__form__email-input-section__email-validation">{signupErrorMessage}</label> : "" }
                     
                        { validEmail ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validEmail) && (email.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>
                    
                    <div className={handlePasswordInputStyle(validPassword, password.length)}>
                        <label htmlFor="password">{t("login.password")}</label>
                        <input
                        className={getInputFieldState(validPassword, password.length)}
                        type="password"
                        maxLength={maxPasswordLength}
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        />

                        {
                            alerts.map((el, index) => <PasswordLabel key={index} text={el.text} status={ el.status || (password.length === 0)} />)
                        }

                        { validPassword ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validPassword) && (password.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>
                    <div className={handlePasswordInputStyle(validPasswordConfirmation, confirmPassword.length)}>
                        <label htmlFor="password-confirm">{t("signUp.confirmPassword")}</label>
                        <input
                        className={getInputFieldState(validPasswordConfirmation, confirmPassword.length)}
                        type="password"
                        maxLength={maxPasswordLength}
                        id="password-confirm"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        />

                        <PasswordLabel text={t("signUp.passwordsNotTheSame")} status={ validPasswordConfirmation || (confirmPassword.length === 0)} />

                        { validPasswordConfirmation ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validPasswordConfirmation) && (confirmPassword.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>

                    <CheckBox
                        formChecked={formChecked}
                        setFormChecked={setFormChecked}
                        
                        validPassword={validPassword}
                        validEmail={validEmail}
                        validPasswordConfirmation={validPasswordConfirmation}
                    />

                    <div className="resetPassword-panel__form__buttons">
                        <button type="submit" className={ handleSubmitButtonStyle() + " button--huge-mobile"}>{t("signUp.signUp")}</button>
                    </div>
                </form>
                
            </div>

            <div className="resetPassword-panel__svg-container login-panel__svg-container">
            <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/signupGraphic.png" alt="" />
            </div>
        </div>
    );
}