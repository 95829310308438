import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { OpinionBackground } from "../graphics/OpinionBackground";

import "./features.scss";

interface FeatureProps {
    id: number;
    isActive: boolean;
    feature: string;
    text: string;
    handleClick: (id: number) => void;
}


const Feature = (props: FeatureProps) => {    

    const { isActive } = props;

    return (
        <>
            <div
                className="main-page__features__left__list-container__element" 
                list-expanded={isActive.toString()} onClick={() => props.handleClick(props.id)}
            >   
                <div className={`main-page__features__left__list-container__element__num ${isActive ? 'active' : 'inactive'}`}>
                    <span>
                        {props.id+1}
                    </span>
                </div>

                <span className={`main-page__features__left__list-container__element__text ${isActive ? 'active' : 'inactive'}`}>
                    {props.feature}
                </span>
            </div>

            <div className={`main-page__features__left__list-container__list-text ${isActive ? 'active' : 'inactive'}`}>
                { props.text }
            </div>     
        </>
    )
}

function useInterval(callback: () => void, delay: number): void {
    const savedCallback = useRef<() => void>();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        savedCallback.current?.();
      }, delay);
  
      return () => clearInterval(intervalId);
    }, [delay]);
  }

interface FeaturesElement {
    id: number;
    feature: string;
    text1: string;
    text2: string;
    isActive: boolean;
}

export const Features = () => {
    const { t } = useTranslation();

    const [activeScrollIndex, setActiveScrollIndex] = useState(0);
    
    const [activeIndex, setActiveIndex] = useState(0);

    const [featuresElement, setFeaturesElement] = useState<FeaturesElement[]>([        
        {
            id: 0,
            feature: "Każda lekcja jest animowana",
            text1: "Masz dość niestarannego pisania na tablecie przez prowadzących? W Nasesje poświęcamy setki godzin, aby wszystkie materiały zostały starannie zanimowane.",
            text2: "Niektóre lekcje składają się nawet z 200 elementów, które zostały ze sobą specjalnie ułożone w procesie animacji.",
            isActive: true,
        },
        {
            id: 1,
            feature: "Prowadzący z pasją",
            text1: "Nasza platforma to nie tylko materiały dydaktyczne. Mocno wierzymy w to, że o jakości naszych materiałów decydują przede wszystkim stojący za nimi ludzie.",
            text2: "Wielu prowadzących oprócz przygotowania samych kursów czynnie angażuje się w rozwój platformy.",
            isActive: false,
        },
        {
            id: 2,
            feature: "System: quizy + zadania + notatki",
            text1: "Lekcja to nie tylko merytoryczne nagranie. Do każdej lekcji w kursie dołączony jest zestaw quizów, zadań otwartych oraz notatek.",
            text2: "Oprócz standardowego przerabiania zadań udostępniamy również Tryb powtórki, który pozwala na przerabianie samych zadań quizów czy też notatek.",
            isActive: false,
        },
        {
            id: 3,
            feature: "Nagrania do zadań otwartych",
            text1: "Chyba każdy zna to uczucie gdy czytając rozwiązanie gotowego zadania nie do końca wiemy o co chodziło w nim autorowi. Aby tego uniknąć do każdego zadania otwartego dołączamy tłumaczące je nagranie.",
            text2: "Długość nagranych omówień zadań otwartych niejednokrotnie przerasta długość lekcji do której zostały one nagrane.",
            isActive: false,
        },
        {
            id: 4,
            feature: "Sprawdzający test końcowy",
            text1: "Po przerobieniu wszystkich działów nadchodzi dobry moment aby się sprawdzić. Do każdego kursu przygotowaliśmy dla ciebie test końcowy z różnymi interaktywnymi typami zadań.",
            text2: "Specjalnie z myślą o teście końcowym przygotowaliśmy nowe typy zadań, których nie znajdziesz w zadaniach otwatych i quizach dołączonych do lekcji.",
            isActive: false,
        }
    ]);

    const handleClick = (id: number) => {
        const updatedFeaturesList = featuresElement.map(feature => {
            if (feature.id === id) {
                setActiveIndex(id);
                return { ...feature, isActive: true };
            }
            
            return { ...feature, isActive: false };
        });

        setFeaturesElement(updatedFeaturesList);
    }

    useInterval(() => {
      setActiveScrollIndex((activeScrollIndex + 1) % 3);
    }, 3000);

    return (
        <div className="main-page__features">
            <OpinionBackground/>

            <div className="main-page__features__left">
                <div className="main-page__features__left__heading">
 
                    <span className="main-page__features__left__heading__white">
                        Zobacz jak wygląda nauka z <span className="yellow">Nasesje<b>.com</b></span> 
                    </span>

                </div>

                <div className="main-page__features__left__list-container">
                    {
                        featuresElement.map(el => 
                            <Feature 
                                key={el.id} 
                                id={el.id} 
                                isActive={el.isActive} 
                                feature={el.feature} 
                                text={el.text1}
                                handleClick={handleClick}
                            />
                        )
                    }

                </div>

            </div>

            <div className="main-page__features__right">

                <div>
                    <img src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/features-panel/section_0${activeIndex+1}/player_0${activeScrollIndex+1}.jpg`} alt=""></img>

                    <div className="main-page__features__right__scroll">
                        <div className={`main-page__features__right__scroll__element ${(0 === activeScrollIndex) ? "active" : "inactive"}`}>
                        
                        </div>
                        <div className={`main-page__features__right__scroll__element ${(1 === activeScrollIndex) ? "active" : "inactive"}`}>
                        
                        </div>
                        <div className={`main-page__features__right__scroll__element ${(2 === activeScrollIndex) ? "active" : "inactive"}`}>
                        
                        </div>

                    </div>

                    <div className="main-page__features__right__text main-page__features__right__text--top main-page__features__right__text--desktop">
                        <span className="main-page__features__right__text--opaque">
                            Projektując platformę postanowiliśmy zaadresować największe problemy nauczania w formie zdalnej i przekuć je w zalety stanowiące o naszej jakości.
                        </span>
                    </div>

                    <div className="main-page__features__right__text">
                        <span className="main-page__features__right__text--white">ciekawostka: </span>
                        <span className="main-page__features__right__text--opaque">
                            { featuresElement[activeIndex].text2 }
                        </span>
                    </div>
                </div>
            </div>

        </div>
    )
}