
import "./authors.scss";

export interface AuthorsProps {
    imgSrc: string;
    name: string;
    description: string;
    bulletPoints: string[];
    myCourses?: string[]; 
}



export const Authors = (props: AuthorsProps) => {

    const { imgSrc, name, description, bulletPoints } = props;

    return (
        <div className="course-preview__main-section__left-panel__text-section__about-author__author">
            <div className="course-preview__main-section__left-panel__text-section__about-author__author__img-wrapper">
                <img alt="author" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/authors/${imgSrc}.png`}/>
            </div>
            <div className="course-preview__main-section__left-panel__text-section__about-author__author__description">
                <h2>{name}</h2>
                <span className="course-preview__main-section__left-panel__text-section__about-author__author__description__text">
                    {description}
                </span>

                {
                    bulletPoints.map((el, index) => 
                        <div key={index} className="course-preview__main-section__left-panel__text-section__about-author__author__description__bullet-section">
                            <div className="course-preview__main-section__left-panel__text-section__about-author__author__description__bullet-section__bullet"/>
                            <span>
                                {el}
                            </span>
                        </div>   
                    )
                }
            </div>
        </div>
    )
}