import { post } from "./rest/apiClient";

export const handleStatsRegister = (
    pageName: string,
    cookieAccepted: boolean,
    cookieUnaccepted: boolean,
    buyClick: boolean,
    secondBuyClick: boolean,
    watchPreviewClick: boolean,
    error?: string
) => {

        const data = { 
            pageName,
            cookieAccepted,
            cookieUnaccepted,
            buyClick,
            secondBuyClick,
            watchPreviewClick,
            error
        };

        post<any, any>(`pageStats/updateStats`, data)
        .then(() => {

        })
        .catch((error) => {

        });
    
};

export const handleCoursePageStatsRegister = (
    courseName: string,
    path: string,
    newUser: boolean,
    showCourseClick: boolean,
    showLessonClick: boolean
) => {
        const fromLanding = (path === '/');

        const data = { 
            courseName,
            fromLanding,
            newUser,
            showCourseClick,
            showLessonClick
        };

        post<any, any>(`pageStats/updateCoursePanelStats`, data)
        .then(() => {

        })
        .catch((error) => {

        });
    
};