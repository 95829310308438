import { Dispatch, SetStateAction, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { svgServerUrl } from "../../../../../dummyUtilities/svgServer";
import { QuizContext } from "../QuizContextProvider";
import "./quizResult.scss";
import { combineBlocks } from "../../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { AuthContext } from "../../../../auth/authContext/AuthContextProvider";
import { get, delete_ } from "../../../../../utilities/rest/apiClient";
import { CourseContext } from "../../../CourseContextProvider";
import { ClosedTestAnswer } from "../../../../../../../shared/closedTestAnswer";
import { Answer, SingleQuiz } from "../../../../../../../shared/quizesDto";
import { NotFound } from "../../../../../utilities/rest/errors";
import { MainContext } from "../../../main/MainContextProvider";
import Latex from "react-latex";

interface QuizResultProps {
    isLoading: boolean;
    setReset: Dispatch<SetStateAction<boolean>>;
}

interface delete_Result {
    status: string
}

type PrevNext = "prev" | "next";

export enum AnswerType {
    CORRECT,
    USER
}

export const handleButtonClass = (type: PrevNext, currentIndex: number, length: number) => {
    const base = "data-panel__results-1__buttons__button";

    let finalClassName = `${base} ${base}--${type}`;
    let inactive = false;

    if(type === "prev") inactive = (currentIndex === 0);
    else if(type === "next") inactive = ((currentIndex+1) === length);


    finalClassName += inactive ? ` ${base}--inactive` : "";
    
    return finalClassName;
}

interface AnswerForQuestionProps {
    answerType: AnswerType;
    correctAnswer: Answer;
    userAnswer: Answer;
}

export const AnswerForQuestion = (props: AnswerForQuestionProps) => {
    let answer: Answer;
    if(props.answerType === AnswerType.CORRECT) {
        answer = props.correctAnswer;
    } else {
        answer = props.userAnswer;
    }
    const srcIsImage = answer.srcIsImage;
    const src = answer.imageSrc;

    const processLeqSigns = (text: string) => {
        return text.replace(/&lt;/g, "<");
    }

    return srcIsImage ? <img alt=""   src={src} /> : 
        <>
            
            <img alt="" src={"https://na-sesje-public.s3.eu-central-1.amazonaws.com/empty_answer.svg"}/>
                <div className="text-anwser">
                    <span>
                        <Latex>{processLeqSigns(src)}</Latex>
                    </span>
                </div>

        </>

}

export const QuizResult = (props: QuizResultProps) => {
    const { t } = useTranslation();
    const quizContext = useContext(QuizContext);
    const authContext = useContext(AuthContext);
    const courseContext = useContext(CourseContext);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentWrongQuestion, setCurrentWrongQuestion] = useState(0);
    const [currentAllQuesstion, setCurrentAllQuestion] = useState(0);
    const [activeQuizes, setActiveQuizes] = useState(quizContext.quizes);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [numberOfCorectAnswers, setNumberOfCorrectAnswers] = useState(0);
    const [wrongQuizes, setWrongQuizes] = useState<SingleQuiz[]>({} as SingleQuiz[]);
    const [answers, setAnswers] = useState<ClosedTestAnswer[]>({} as ClosedTestAnswer[]);
    const mainCtx = useContext(MainContext);

    useEffect(() => {
        setIsLoading(true);
        (async ()=> {
            if(!props.isLoading){
                try {
                  const data = await get<ClosedTestAnswer[]>(`userChapters/getClosedTestCompletion/${courseContext.getLessonId()}`);
                  if(data.length === 0) {
                    throw new NotFound("lesson contains zero tests");
                  }
                  setAnswers(data);
                  setNumberOfCorrectAnswers(getNumberOfCorrectAnswers(data));
                  setWrongQuizes(getWrongQuizes(data));
                  setIsLoading(false);
                } catch(error) {
                  authContext.handleAuthErrors(error);
                  //TODO obłsuga błędu
                  console.log(error);
                }   
            }
          })()
    }, [mainCtx.active, courseContext.activeChapter])

    const getCorrectAnswer = () => {
        let questionIndex = currentQuestion;
        if(showAll){
            questionIndex = wrongQuizes[currentQuestion].id;
        }
        return quizContext.quizes[questionIndex].answers[quizContext.quizes[questionIndex].correctAnswerId];
    }

    const getUserAnswer = () => {
        let questionIndex = currentQuestion;
        if(showAll){
            questionIndex = wrongQuizes[currentQuestion].id;
        }
        return quizContext.quizes[questionIndex].answers[answers[questionIndex].givenAnswer-1];
    }

    const redoQuiz = async () => {
        try {
            await delete_<any, delete_Result>(`userChapters/deleteClosedTestCompletion/${courseContext.getLessonId()}`);
        } catch(error) {
            // TODO: handle error
        }

        props.setReset(true);
    } 

    const getNumberOfCorrectAnswers = (answers: ClosedTestAnswer[]) => {
        return answers.filter(element => element.goodAnswer === true).length;
    }

    const getWrongQuizesIds = (answers: ClosedTestAnswer[]) => {
        return answers.filter(element => element.goodAnswer === false);
    }

    const getWrongQuizes = (answers: ClosedTestAnswer[]) => {
        const wrongQuizes = getWrongQuizesIds(answers);
        return wrongQuizes.map(element => quizContext.quizes[element.questionNumber-1]);
    }

    const handleCheckboxClick = () => {
        if(!showAll && wrongQuizes.length === 0) return;

        if(!showAll){
            setActiveQuizes(wrongQuizes);
            setCurrentQuestion(0);
            // console.log(wrongQuizes[0])
        } else {
            setActiveQuizes(quizContext.quizes);
            setCurrentQuestion(0);
        }

        setShowAll(!showAll);
    }

    const handleNextClick = () => {
        const nextQuestionIndex = currentQuestion < activeQuizes.length - 1 ? currentQuestion + 1 : currentQuestion;
        setCurrentQuestion(nextQuestionIndex);

        if(!showAll) {
            setCurrentWrongQuestion(nextQuestionIndex);
        } else {
            setCurrentAllQuestion(nextQuestionIndex);
        }
    }

    const handlePreviousClick = () => {
        const previousQuestion = currentQuestion > 0 ? currentQuestion - 1 : currentQuestion;
        setCurrentQuestion(previousQuestion);

        if(!showAll) {
            setCurrentWrongQuestion(previousQuestion);
        } else {
            setCurrentAllQuestion(previousQuestion);
        }
    }

    const handleDisplayedMessage = () => {
        return showAll ? t("kurs.quiz.showOnlyWrongAnswers") : t("kurs.quiz.showAllAnswers");
    }

    const getQuestionText = () => {
        let questionIndex = currentQuestion;
        // console.log("initial current: " + currentQuestion)
        if(showAll){
            questionIndex = wrongQuizes[currentQuestion].id;
            // console.log("final current " + questionIndex)
        }
        // console.log("len " + quizContext.quizes.length)
        return combineBlocks(quizContext.quizes[questionIndex].text)
    }

    if(props.isLoading || isLoading) {
        return <></> //TODO fallback
    } else {
        return (
            <div className="data-panel__results-1">
                <div className="data-panel__results-1__score">
                    <h4>
                        {t("kurs.quiz.odpowiedzialesDobrzeNa")}<span>{numberOfCorectAnswers} </span> {t("kurs.quiz.z")} <span>{quizContext.quizes.length}</span> {t("kurs.quiz.mozliwychPytan")}
                    </h4>
                </div>
    
                <div className="data-panel__results-1__correct-anwsers">
    
                    <div className="data-panel__results-1__correct-anwsers__checkbox">
                        <label htmlFor="click" className={`text text--${showAll? "inactive": "active"}`}>{handleDisplayedMessage()}</label>
                        <input type="checkbox" id="click" checked={!showAll} onChange={handleCheckboxClick}/>
                    </div>
    
                    <div className="data-panel__results-1__correct-anwsers__spacer"></div>
                    
                    <div className="data-panel__results-1__correct-anwsers__question-number">
                        <h4>{t("kurs.quiz.pytanie")}: {currentQuestion+1}/{activeQuizes.length}</h4>
                    </div>
    
                    <div className="data-panel__quiz__question">
                        <div className="data-panel__quiz__question__text">
                            {getQuestionText()}
                        </div>
                    </div>
                    
                    <div className="data-panel__results-1__correct-anwsers__headlines-spacer" />
    
                    <div className="data-panel__results-1__correct-anwsers__headlines-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--correct">
                            <span className="material-symbols-outlined">check_box</span>
                            <span>{t("kurs.quiz.yourAnswer")}</span>
                        </div>
    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                            <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                        </div>
                    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--yours">
                            <span className="material-symbols-outlined">indeterminate_check_box</span>
                            <span>{t("kurs.quiz.correctAnswer")}</span>
                        </div>
    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                            <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                        </div>
                    </div>
    
                    <div  className="data-panel__results-1__correct-anwsers__anwsers-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--left">
                            <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                        </div>
                        <div  className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--right">
                            <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                        </div>
                    </div>
                </div>
    
                <div className="data-panel__results-1__buttons">
                    <div className={handleButtonClass("prev", currentQuestion, activeQuizes.length)} onClick={handlePreviousClick}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                        <span>{t("kurs.quiz.previous")}</span>
                    </div>

                    <div onClick={redoQuiz} className="data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center  data-panel__results-1--mobile-hidden">
                        <span className="material-symbols-outlined">replay</span>
                        <span>{t("kurs.quiz.repeatQuiz")}</span>
                    </div>

                    <div className={handleButtonClass("next", currentQuestion, activeQuizes.length)} onClick={handleNextClick}>
                        <span>{t("kurs.quiz.next")}</span>
                        <span className="material-symbols-outlined">arrow_forward_ios</span>
                    </div>
                </div>
                <div onClick={redoQuiz} className="data-panel__results-1__repeat-button-mobile">
                    <div className="data-panel__results-1__repeat-button-mobile__button data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center">
                        <span className="material-symbols-outlined">replay</span>
                        <span>{t("kurs.quiz.repeatQuiz")}</span>        
                    </div>
                </div>
            </div>
        );
    }
}