
export const ArrowForwardIos600 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"

    viewBox="0 -960 960 960"

  >
    <path d="M321-61.91 231.91-151l329-329-329-329L321-898.09 739.09-480 321-61.91Z" />
  </svg>
)

