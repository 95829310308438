import { useContext, useEffect, useState } from "react";


import "./cookie.scss";
import { AllowedCookiesInterface, AuthContext } from "../../../components/auth/authContext/AuthContextProvider";
import { handleStatsRegister } from "../../../utilities/pageStatsLogging";

import { useLocation } from 'react-router-dom';
import { GoBackArrow } from "../../Icons/goBackArrow";
import { CookieIcon } from "../../Icons/CookieIcon";
import MediaQuery from "react-responsive";


interface CookieRow {
    key: string;
    domain: string;
    type: string;
    expire: string;
    function: string;
}

interface AdvancedDataInterface {
    headline: string;
    text: string;
    cookies: CookieRow[];
}



export const Cookie = () => {

    const location = useLocation();


    const authContext = useContext(AuthContext);

    const [cookiePanelActive, setCookiePanelActive] = useState(false);
    const [advancedCookieSettings, setAdvancedCookieSettings] = useState(false);

    const [firstSectionActive, setFirstSectionActive] = useState(true);

    const handleMenuTurn = (state: boolean) => {
        let html = document.querySelector('html');
        
        if(state) {
            setCookiePanelActive(true);
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            setCookiePanelActive(false);
            setAdvancedCookieSettings(false);
            setFirstSectionActive(true);
            if(html !== null) html.classList.remove('html-not-scrollable');
            document.body.classList.remove('body-not-scrollable');
        }
    } 

    useEffect(() => {
        if((!authContext.loggedIn) && (!authContext.isLoading)) {

            const cookieData = authContext.allowedCookies;

            if((cookieData !== null) && (cookieData !== undefined)) {

                if(cookieData.fromBrowser) return;
            }

            handleMenuTurn(true);
            
        }

    }, [authContext.isLoading, authContext.loggedIn, authContext.allowedCookies]);

    const acceptCookies = (stat: boolean, prefCookies: boolean) => {

        handleStatsRegister(
            location.pathname,
            stat,
            !stat,
            false,
            false,
            false,
        );

        handleMenuTurn(false);

        const allowedCookies: AllowedCookiesInterface = {
            fromBrowser: true,
            statCookies: stat,
            prefCookies: prefCookies,
        };  
        
        addCookie(allowedCookies);
    }


    const addCookie = (allowedCookies: AllowedCookiesInterface) => {
          
        const allowedCookiesString = JSON.stringify(allowedCookies);   

        document.cookie = encodeURIComponent(JSON.stringify(allowedCookies));

        const expires = new Date();
        expires.setTime(expires.getTime() + 395 * 24 * 60 * 60 * 1000); // expire 13 

        document.cookie = `cookie-memo=${allowedCookiesString}; expires=${expires.toUTCString()}; path=/;`;
    }

    const advancedData: AdvancedDataInterface[] = [
        {
            headline: "Niezbędne",
            text: "Niezbędne pliki cookies umożliwiają prawidłowe działanie serwisu, zapewniając podstawowe funkcje, takie jak: uwierzytelnianie użytkownika czy obsługa płatności. Bez tych plików korzystanie ze strony jest niemożliwe.",
            cookies: [

                { key: "m", domain: "m.stripe.com", type: "Third-party", expire: "13 miesięcy", function: "Obsługa systemu płatności"},
                { key: "cookie-memo", domain: ".nasesje.com", type: "First-party", expire: "13 miesięcy", function: "Zapamiętywanie ustawień cookie"},
                { key: "__stripe_mid", domain: ".nasesje.com", type: "First-party", expire: "1 rok", function: "Obsługa systemu płatności"},
                { key: "__stripe_sid", domain: ".nasesje.com", type: "First-party", expire: "30 minut", function: "Obsługa systemu płatności"},
            ]
        },
        {
            headline: "Statystyka",
            text: "Pliki cookies z tej kategorii pomagają nam zrozumieć, w jaki sposób odwiedzający wchodzą w interakcje ze stroną, zbierając i raportując informacje w sposób anonimowy.",
            cookies: [
                { key: "_ga", domain: ".nasesje.com", type: "First-party", expire: "13 miesięcy", function: "Badanie statystyk użytkowania strony"},
                { key: "_ga_CMENY1 P3C9", domain: ".nasesje.com", type: "First-party", expire: "13 miesięcy", function: "Badanie statystyk użytkowania strony"},
            ]
        },
        {
            headline: "Preferencje",
            text: "Pliki cookies preferencji pozwalają stronie internetowej zapamiętywać informacje, które zmieniają sposób, w jaki strona się zachowuje (np. preferowany język, ustawienia itp.).",
            cookies: [
                { key: "brak cookie", domain: "", type: "", expire: "", function: ""},
            ]
        },
    ];
        
    const [displayedStates, setDisplayedStates] = useState(
        advancedData.map(() => ({
          isDisplayed: false,
        }))
    );

    const toggleDisplay = (index: number) => {
        setDisplayedStates((prevState) =>
          prevState.map((state, idx) =>
            idx === index ? { ...state, isDisplayed: !state.isDisplayed } : state
          )
        );
    };

            
    const [allowedCookies, setAllowedCookies] = useState(
        advancedData.map(() => ({
          isDisplayed: true,
        }))
    );

    const toggleAllowedCookies = (index: number) => {
        setAllowedCookies((prevState) =>
          prevState.map((state, idx) =>
            idx === index ? { ...state, isDisplayed: !state.isDisplayed } : state
          )
        );
    };

    useEffect(() => {
        const cookies = authContext.allowedCookies;
        if(cookies.fromBrowser) {
            setAllowedCookies([
                { isDisplayed: true }, 
                { isDisplayed: cookies.statCookies }, 
                { isDisplayed: cookies.prefCookies }
            ]);
        }

    }, [authContext.allowedCookies]);


    
    const advancedSettingsElement = (headline: string, text: string, index: number, openList: boolean, cookieData: CookieRow[]) => {

        const handleSettingChange = () => {
            if(index!==0) {
                toggleAllowedCookies(index)
            }
        }

        return (
            <div key={index} className="main-page__cookie-panel__advanced-settings-panel__data__sections__section">
                <h3>{headline}</h3>
                <div className="main-page__cookie-panel__advanced-settings-panel__data__sections__section__description">
                    <div className="main-page__cookie-panel__advanced-settings-panel__data__sections__section__description__text">
                        <span>{text}</span>
                    </div>
                    <div className="main-page__cookie-panel__advanced-settings-panel__data__sections__section__description__toggle">
                        <input 
                            className={`${(index === 0) ?'inactive' : 'active'}`}
                            type="checkbox" id="click" 
                            checked={(index!==0) ? allowedCookies[index].isDisplayed : true} 
                            onChange={() => handleSettingChange()}
                        />
                    </div>
                </div>
                <div 
                    onClick={() => toggleDisplay(index)}
                    className="main-page__cookie-panel__advanced-settings-panel__data__sections__section__toggle"
                >
                    <span>PLIKI COOKIES</span>
                    <span className={`material-symbols-outlined ${openList ? 'active' : 'inactive'}`}>
                        arrow_forward_ios
                    </span>
                </div>
                {
                    openList && 
                    <div className="array-wrapper">
                        <div className="main-page__cookie-panel__advanced-settings-panel__data__sections__section__array">
                            <div className="headline">
                                <div className="headline__name w1">Klucz</div>
                                <div className="headline__name w2">Domena</div>
                                <div className="headline__name w3">Typ cookie</div>
                                <div className="headline__name w4">Czas trwania</div>
                                <div className="headline__name w5">Zastosowanie</div>
                            </div>
                            {
                                cookieData.map((el, index) => 
                                    <div key={index} className={`array-row array-row--${(index % 2) ? 'dark' : 'light'} array-row--${(index===(cookieData.length-1)) ? 'last' : 'middle'}`}>
                                        <div className="array-row__name w1">{el.key}</div>
                                        <div className="array-row__name w2">{el.domain}</div>
                                        <div className="array-row__name w3">{el.type}</div>
                                        <div className="array-row__name w4">{el.expire}</div>
                                        <div className="array-row__name w5">{el.function}</div>
                                    </div>   
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    return (<>

        {
            cookiePanelActive && 
            <div className="main-page__cookie-panel">
                <div className="main-page__cookie-panel__background"></div>

                {
                    (cookiePanelActive  && (!advancedCookieSettings))&& 
                    <div className="main-page__cookie-panel__panel">
                        <div className="main-page__cookie-panel__panel__headline">

                            <span className="mobile-hidden">Ta strona korzysta z plików Cookie 🍪</span>
                            <span className="desktop-hidden">Pliki Cookies 🍪</span>
                        </div>  
                        <div className="main-page__cookie-panel__panel__spacer"></div>
                        <div className="main-page__cookie-panel__panel__text">
                            <span>
                                <span className="mobile-hidden">Wykorzystujemy pliki cookie, aby zwiększyć wygodę użytkowania strony.Korzystając z platformy <b>nasesje.com</b>, wyrażasz zgodę na stosowanie plików cookies zgodnie z zasadami naszego <b>regulaminu</b>.</span>
                                <span className="desktop-hidden">Platforma <b>nasesje.com</b> korzysta z plików cookies zgodnie z <b>regulaminem</b>.</span>
                            </span>
                            
                        </div>          

                        <div className="main-page__cookie-panel__panel__buttons">
                            <div
                                onClick={() => setAdvancedCookieSettings(true)}  
                                className="main-page__cookie-panel__panel__buttons__button main-page__cookie-panel__panel__buttons__button--empty">
                                <span>Dostosuj ustawienia</span>
                                <GoBackArrow/>
                                {/* <span className="material-symbols-outlined">
                                    arrow_forward_ios
                                </span> */}
                            </div>
                            <div
                                onClick={() => {
                                    authContext.setAllowedCookies({
                                        fromBrowser: true,
                                        statCookies: true,
                                        prefCookies: true
                                    });
                                    acceptCookies(true, true) 
                                }}
                                className="main-page__cookie-panel__panel__buttons__button main-page__cookie-panel__panel__buttons__button--full">
                                <span>Zaakceptuj wszystkie</span>
                            </div>
                        </div>


                        <div onClick={() => setAdvancedCookieSettings(true)} className="main-page__cookie-panel__panel__settings">
                            <span>więcej informacji</span>
                            <span className="material-symbols-outlined">
                            info
                            </span>
                        </div>
                    </div>
                }

                {
                    (cookiePanelActive && advancedCookieSettings) && 
                    <div className="main-page__cookie-panel__advanced-settings-panel">
                        
                        <div className="main-page__cookie-panel__advanced-settings-panel__headline">
                            <div 
                                onClick={() => setFirstSectionActive(true)}
                                className={`main-page__cookie-panel__advanced-settings-panel__headline__button main-page__cookie-panel__advanced-settings-panel__headline__button--${firstSectionActive ? 'active' : 'inactive'} main-page__cookie-panel__advanced-settings-panel__headline__button--left`}
                            >
                                <span>
                                    Ustawienia zaawansowane
                                </span>
                                <span className="material-symbols-outlined">
                                    settings
                                </span>
                            </div>
                            <div className="main-page__cookie-panel__advanced-settings-panel__headline__spacer"></div>
                            <div 
                                onClick={() => setFirstSectionActive(false)}
                                className={`main-page__cookie-panel__advanced-settings-panel__headline__button main-page__cookie-panel__advanced-settings-panel__headline__button--${!firstSectionActive ? 'active' : 'inactive'} main-page__cookie-panel__advanced-settings-panel__headline__button--right`}
                            >
                                <span>
                                    O plikach cookie
                                </span>
                                <span className="material-symbols-outlined">
                                    info
                                </span>
                            </div>
                        </div>

                        <div className="main-page__cookie-panel__advanced-settings-panel__data">
                            {
                                firstSectionActive ? (
                                <div className="main-page__cookie-panel__advanced-settings-panel__data__sections">
                                   
                                    {
                                        advancedData.map(
                                            (el, index) => 
                                                advancedSettingsElement(
                                                    el.headline, 
                                                    el.text, 
                                                    index, 
                                                    displayedStates[index].isDisplayed,
                                                    el.cookies
                                                )
                                        )
                                    }

                                </div> ) : (
                                    <div className="main-page__cookie-panel__advanced-settings-panel__data__about-cookies">
                                        <span>
                                            Pliki cookie to niewielkie pliki tekstowe, które mogą być wykorzystywane przez strony internetowe, aby korzystanie z nich było wygodniejsze (i często możliwe) dla użytkownika.
                                        </span>
                                        <span>
                                            Zgodnie z obowiązującym prawem możemy przechowywać pliki cookie na Twoim urządzeniu, jeśli są one niezbędne do działania tej strony.
                                        </span>
                                        <span>
                                            W przypadku wszystkich innych rodzajów plików cookie (które wymieniliśmy w sekcji "ustawienia zaawansowane") potrzebujemy Twojej zgody. Strona <b>nasesje.com</b> używa różnych typów plików cookie. Niektóre z nich są umieszczane przez zewnętrzne usługi, które pojawiają się na naszych stronach.
                                        </span>
                                        <span>
                                        Co ważne, w każdej chwili możesz zmienić lub wycofać swoją zgodę poprzez Deklarację Cookie zawartą na naszej stronie internetowej. Bardziej szczegółowe informacje znajdziesz w sekcjach: <b>"obowiązki informacyjne"</b> oraz <b>"polityka prywatności"</b>, do których linki umieszczone są w stopce strony.
                                        </span>
                                    </div>
                                )
                            }
                        </div>

                        <div className="main-page__cookie-panel__advanced-settings-panel__buttons">
                            <div className="main-page__cookie-panel__advanced-settings-panel__buttons__left-group">
                                <div 
                                    onClick={() => {

                                            authContext.setAllowedCookies({
                                                fromBrowser: true,
                                                statCookies: false,
                                                prefCookies: false
                                            });

                                            acceptCookies(false, false);
                                    
                                        } 
                                    }
                                    className="main-page__cookie-panel__advanced-settings-panel__buttons__left-group__button">
                                    <span>Tylko niezbędne</span>
                                </div>  
                                <div 
                                    onClick={() => {
                                        authContext.setAllowedCookies({
                                            fromBrowser: true,
                                            statCookies: true,
                                            prefCookies: true
                                        });
                                        acceptCookies(true, true) 
                                    }}
                                    className="main-page__cookie-panel__advanced-settings-panel__buttons__left-group__button mobile-hidden">
                                    <span>Zaakceptuj wszystkie</span>
                                </div>  
                            </div>

                            <div
                                onClick={() => {
                                        authContext.setAllowedCookies({
                                            fromBrowser: true,
                                            statCookies: allowedCookies[1].isDisplayed,
                                            prefCookies: allowedCookies[2].isDisplayed
                                        });
                                        acceptCookies(allowedCookies[1].isDisplayed, allowedCookies[2].isDisplayed);
                                    }
                                } 
                                className="main-page__cookie-panel__advanced-settings-panel__buttons__button">
                                <span>Akceptuj wybrane</span>
                            </div>    
                        </div>
                    </div>
                }

            </div>
        }



        <MediaQuery minWidth={951}>

            <div className="main-page__cookie" onClick={() => handleMenuTurn(true)}>
                <CookieIcon/>
            </div>

            <div className="main-page__cookie-text">
                <span>
                    Ustawienia plików Cookies 🍪
                </span>
            </div>

        </MediaQuery>
    </>);
}