
import { QuoteIconFilled } from "../../Icons/QuoteIconFilled400";
import "./newOpinions.scss";



interface OpinionProps {
    name: string,
    source: string,
    text: string,
    img: string,
    last: boolean
}

const Opinion = (props: OpinionProps) => {
    return (
        <div className={`main-page__new-opinions__opinions__opinion ${props.last ? "last" : ""}`}>
            <QuoteIconFilled/>
            <div className="main-page__new-opinions__opinions__opinion__text">
                <span>{props.text}</span>
            </div>
            <div className="main-page__new-opinions__opinions__opinion__name">
                ~{props.name}
            </div>
            <div className="main-page__new-opinions__opinions__opinion__source">
                <span>kurs:</span>
                <br/>
                "{props.source}"
            </div>
        </div>
    )
}

export const NewOpinions = () => {

    const opinionData = [
        { name: "Ania", source: "liczby zespolone", text: "Na ćwiczeniach niepotrzebnie komplikują ten temat, nie wiedziałam, że to jest tak naprawdę takie proste.", img: "" },
        { name: "Kuba", source: "granice ciągów", text: "W 30 minut zrozumiałem więcej niż przez 2h siedzenia na wykładzie z tego samego tematu xd", img: "" },
        { name: "Tomek", source: "liczby zespolone",  text: "Dzięki wam zdałem pierwszego kolosa, wystarczyły zadania z nagrań, nawet bez tych dodatkowych do lekcji.", img: "" },
        { name: "Martyna", source: "macierze i wektory", text: "Fajnie napisane notatki do lekcji, dużo lepsze od slajdów z wykładu.", img: "" }
    ]

    return (
        <div className="main-page__new-opinions">
            <div className="main-page__new-opinions__headline">
                <span className="main-page__new-opinions__headline__text-1">
                    Opinie ❤️
                </span>
                <span className="main-page__new-opinions__headline__text-2">
                    Co mówią o nas <b>studenci</b>?
                </span>
            </div>

            <div className="main-page__new-opinions__opinions">
                {
                    opinionData.map((el, index) => <Opinion
                        {...el}
                        key={index}
                        last={index === (opinionData.length-1)}
                    />)
                }
            </div>

            <div className="main-page__new-opinions__tikTok-opinions">
                <span className="main-page__new-opinions__tikTok-opinions__headline">
                    Nasza działalność w sieci:
                </span>
                <div className="main-page__new-opinions__tikTok-opinions__data desktop">
                    { 
                        Array.from({ length: 11 }, (_, index) => index).map((el) =>
                            <img alt="" key={el} src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/comments/kom_${el+1}.png`}/>
                        )
                    
                    }
                    <div className="main-page__new-opinions__tikTok-opinions__data__last-element"><span>i wiele więcej ... </span></div>
                </div>
                <div className="main-page__new-opinions__tikTok-opinions__data mobile">
                    <div className="main-page__new-opinions__tikTok-opinions__data__mobile">
                    { 
                        Array.from({ length: 4 }, (_, index) => index).map((el) =>
                            <img alt="" key={el} src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/comments/kom_${el+1}.png`}/>
                        )
                    
                    }
                    </div>
                    <div className="main-page__new-opinions__tikTok-opinions__data__mobile">
                    { 
                        Array.from({ length: 4 }, (_, index) => index).map((el) =>
                            <img alt="" key={el} src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/comments/kom_${el+5}.png`}/>
                        )
                    
                    }
                    </div>
                    <div className="main-page__new-opinions__tikTok-opinions__data__mobile">
                        {
                            Array.from({ length: 3 }, (_, index) => index).map((el) =>
                                <img alt="" key={el} src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/comments/kom_${el+9}.png`}/>
                            )
                        }
                         <div className="main-page__new-opinions__tikTok-opinions__data__last-element"><span>i wiele więcej ... </span></div>
                    </div>

                   
                </div>
            </div>
        </div>
    )
}