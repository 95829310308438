const ttqtrack = (eventName: string, args: any) => {
    (window as any).ttq.track(eventName, {
        ...args
    });
};

const ttqidentify = (email: string) => {
    (window as any).ttq.identify({
        email: email
    });
};

const fbqtrack = (eventName: string, args: any) => {
    (window as any).fbq('track', eventName, args);
}

export const ViewContent = (content_name: string, content_id: string) => {
    const args = {
        "content_name": content_name,
        "content_id": content_id
    }
    ttqtrack('ViewContent', args)
}

export const CompletePayment = (content_id: string, value: number, email: string) => {
    ttqidentify(email);

    const tiktok_args = {
            "contents": [
                {
                    "content_id": content_id // string. ID of the product. Example: "1077218".
                }
            ],
            "value": value, // number. Value of the order or items sold. Example: 100.
            "currency": "PLN" // string. The 4217 currency code. Example: "USD".
        }
    ttqtrack('CompletePayment', tiktok_args)

    const facebook_args = {
        value: value, currency: 'PLN', content_ids: content_id
    }

    fbqtrack('Purchase', facebook_args);
}