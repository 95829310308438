import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import "./sellBaner.scss";
import { Play } from "../graphics/Play";
import { ArrowForwardIos600 } from "../../Icons/ArrowForwardIos600";


export const SellBaner = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const [delayedOpen, setDelayedOpen] = useState(true);

    const delayedClassChange = () => {
        setTimeout(() => {
            setDelayedOpen(true);
        }, 1000);
    }

    const handleDelayedClass = () => {

        if(open && delayedOpen) return 'active';
        if((!open) && delayedOpen) return 'inactive';

        return '';
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 3000); 

        return () => clearTimeout(timer);
    }, []); 


    const topSellers = [
        { name: "Macierze i wektory", link: "/coursePreview/6706f16a908a9058424a73c3" },
        { name: "Liczby zespolone", link: "/coursePreview/670d43390712d31a534a442d" },
        { name: "Granice", link: "/coursePreview/6706f8c9908a9058424a74a8" }
    ];

    const courseElement = (name: string, link: string, index: number) => {

        return (
                <div 
                    key={index} 
                    className="main-page__sell-baner__courses__course"
                    onClick={() => navigate(link)}
                >
                    <div className="main-page__sell-baner__courses__course__img-wrapper">
                        <Play/>
                        <span className="number">#{index}</span>
                    </div>
                    <div>
                        <span>{name}</span>
                    </div>
                </div>
        );
    }

    return (
        <div className={`main-page__sell-baner main-page__sell-baner--${open ? 'acitve' : 'inactive'}`}>
            
            <div 
                className={`main-page__sell-baner__headline main-page__sell-baner__headline--${handleDelayedClass()} main-page__sell-baner__headline--${open ? 'long' : 'short'}`}
                onClick={() => {
                    setOpen(!open);
                    setDelayedOpen(false);
                    delayedClassChange();
                }}    
            >
                <div className="main-page__sell-baner__headline__row-1">
                    {
                        open &&
                        <span>
                            Nasze
                        </span>
                    }
                    <div className={`main-page__sell-baner__headline__row-1__trophy main-page__sell-baner__headline__row-1__trophy--${open ? 'long' : 'short'}`}>
                        🏆
                    </div>
                </div>
                {
                    open &&
                    <span>
                        <b>Bestsellery</b>
                    </span>
                }

                <span className={`${open ? 'long' : 'short'}`}>
                    <ArrowForwardIos600/>
                </span>

            </div>

            <div className="main-page__sell-baner__courses">
 
                {
                    topSellers.map((el, index) => 
                        courseElement(el.name, el.link, index+1)
                    )
                }

            </div>
        </div>
    );
}