import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../utilities/rest/apiClient";
import { ActiveAddition } from "./Course";
import { CourseVideoChapterDto } from "../../../../shared/courseVideoChapterDto";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../global/globalDataContext/GlobalDataContextProvider";
import { useTranslation } from "react-i18next";
import { TitleWithBackArrowButton } from "../shared/components/titleWithBackButton/TitleWithBackArrowButton";
import { LastVisitedDto } from "../../../../shared/lastVisitedDto";
import { AuthContext } from "../auth/authContext/AuthContextProvider";
import { NotFound } from "../../utilities/rest/errors";
import "./course.scss"
import { WideMenu } from "../shared/components/wideMenu/WideMenu";
import { ContentPanelWrapper } from "../shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { DataPanelWrapper } from "../shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { CourseNavigationFallback } from "./courseNavigation/courseNavigationFallback/CourseNavigationFallback";
import { CoursePathFallback } from "./coursePath/coursePathFallback/CoursePathFallback";
import { TitleHeaderFallback } from "../shared/components/titleHeader/titleHeaderFallback/TitleHeaderFallback";
import { checkIfChapterIsCompleted } from "./courseNavigation/courseNavigationUtils";
import { MainContext } from "./main/MainContextProvider";
import { getContentFallbackWithRegardsToUrl } from "../shared/components/fallbacks/fallbackUtils";

interface CourseContextProps {
    title: string;
    activeChapter: number;
    setActiveChapter: Dispatch<SetStateAction<number>>;
    activeAddition: ActiveAddition;
    setActiveAddition: Dispatch<SetStateAction<ActiveAddition>>;
    path: string[];
    handleChapterClick: (chapterId: number, videoSrc: string, title: string) => void;
    videoSrc: string;
    setVideoSrc: Dispatch<SetStateAction<string>>;
    chapters: CourseVideoChapterDto;
    setChapters: Dispatch<SetStateAction<CourseVideoChapterDto>>;
    courseId: string | undefined;
    getLessonId: () => string;
    getBackButton: () => JSX.Element;
    notesEverVisited: number;
    setNotesEverVisited: Dispatch<SetStateAction<number>>;
    isLoading: boolean;
    currentAssignment: number;
    setCurrentAssignment: Dispatch<SetStateAction<number>>;
}

export const CourseContext = React.createContext<CourseContextProps>({} as CourseContextProps);

interface CurseContextProviderProps {
    children: ReactNode;
}

export const CourseContextProvider = (props: CurseContextProviderProps) => {
    const [notesEverVisited, setNotesEverVisited] = useState(0);
    const [title, setTitle] = useState("");
    const [path, setPath] = useState([""]);
    const [activeChapter, setActiveChapter] = useState(0);
    const [activeAddition, setActiveAddition] = useState<ActiveAddition>('disabled');
    const [videoSrc, setVideoSrc] = useState("");
    const [chapters, setChapters] = useState<CourseVideoChapterDto>({} as CourseVideoChapterDto);
    const [currentAssignment, setCurrentAssignment] = useState(0);
    const { courseId, courseChapterId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const globalCtx = useContext(GlobalDataContext);
    const authContext = useContext(AuthContext);
    const mainCtx = useContext(MainContext);
    
    
    const navigate = useNavigate();
    const getLessonId = () => {
        let currentLessonId: string;
        if(!globalCtx.isMainView){
          currentLessonId = chapters?.lessons[activeChapter].id;
        } else {
          currentLessonId = mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].lessons[mainCtx.active.lessonIndex].id;
        }
        
        return currentLessonId;
    }

    useEffect(() => {
        if(!isLoading){
            checkIfChapterIsCompleted(chapters);
        }
    }, [isLoading])

    useEffect(() => {
        setIsLoading(true);
        (async ()=> {
          let progress: LastVisitedDto = {} as LastVisitedDto;
          let isFreshlyStarted = false;
          try{
            progress = await get<LastVisitedDto>(`reactRoutes/getLastVisited/${courseId}`);
          } catch(error) {
            if(error instanceof NotFound){
                isFreshlyStarted = true;
            }
            authContext.handleAuthErrors(error);
            //TODO other errors
          }
         
            try {            
                if(courseChapterId){
                    const data = await get<CourseVideoChapterDto>(`reactRoutes/getCourseVideoChapter/${courseChapterId}/${i18n.language}`);
                    if(isFreshlyStarted){
                        const firstLesson = data.lessons[0].id;
          
                        progress = {lastVisitedChapter: "", lastVisitedLesson: firstLesson}; //lastVisitedChapter is not necessary in this context
                    }
    
                    const lesson = data.lessons.find(l => l.id === progress.lastVisitedLesson);
    
                    let lessonIndex: number;
                    if(!lesson){
                        lessonIndex = 0;
                        setActiveChapter(lessonIndex);
                        setVideoSrc(data.lessons[lessonIndex].src);
                        post<any, LastVisitedDto>(`reactRoutes/addLastVisited/${courseId}`, {lastVisitedChapter: courseChapterId, lastVisitedLesson: data.lessons[0].id})
                    } else {
                        lessonIndex = data.lessons.indexOf(lesson);
                        setActiveChapter(lessonIndex);
                        setVideoSrc(data.lessons[lessonIndex].src);                    
                    }
                    setChapters(data);
                    const path = [data.courseName, data.chapterName, data.lessons[lessonIndex].title];
                    setPath(path);
                    setTitle(data.lessons[lessonIndex].title);
                    globalCtx.setTitle(data.courseName);
                    setIsLoading(false);
                } else {
                    throw "missing parameter"
                }
                
              } catch(error) {
                //TODO obłsuga błędu
                console.log(error)
              }
        })()
      }, [mainCtx.active])

    useEffect(() => {
        if(!isLoading){
            setPath([chapters.courseName, chapters.chapterName, chapters.lessons[activeChapter].title]);
            setNotesEverVisited(chapters.lessons[activeChapter].notesVisited ? 1 : 0)
        }
    }, [activeChapter])

    const handleChapterClick = (chapterId: number, videoSrc: string, title: string) => {
        if(activeChapter != chapterId) {
            setActiveChapter(chapterId);
            setVideoSrc(videoSrc);
            setTitle(title);
            //TODO zapisz w bazie aktualny timestamp filmiku
        }
        setActiveAddition("disabled");
        if(!window.location.pathname.endsWith("course"))
            navigate("");

        // const chapterId = chapters?.id
        if(courseChapterId && courseId){
            const lessonId = chapters?.lessons[chapterId].id;
            if(lessonId){
                post<any, LastVisitedDto>(`reactRoutes/addLastVisited/${courseId}`, {lastVisitedChapter: courseChapterId, lastVisitedLesson: lessonId})
                .catch(err => console.log(err)); //TODO to trzeba jakoś obsłuzyć. Moze jakiś popup?
            } else {
                //TODO tutaj tak samo
            }
        } else {
            //TODO tutaj tez trzeba jakoś powiadomić uzytkownika, zeby nie grzebał w urlach
        }
    }

    const getBackButton = () => {
        if(globalCtx.isMainView) return <></>;

        // UNUSED ELEMENTS
        // if(activeAddition === 'assignment') {
        //     return <TitleWithBackArrowButton title={`${title} - ${t("kurs.zadaniaOtwarte.zadaniaOtwarte")}`} navigateTo={`/course/${courseId}/main`}/>
        // } else if (activeAddition === 'notes') {
        //     return <TitleWithBackArrowButton title={`${title} - ${t("kurs.notatki.notatki")}`} navigateTo={`/course/${courseId}/main`}/>;
        // } else if (activeAddition === 'quiz') {
        //     return <TitleWithBackArrowButton title={`${title} - ${t("kurs.quiz.test")}`} navigateTo={`/course/${courseId}/main`}/>;
        // } else {
            return <TitleWithBackArrowButton title={title} navigateTo={`/course/${courseId}/main`}/>;
        // }
    }

    if(isLoading && globalCtx.isMainView){
        return getContentFallbackWithRegardsToUrl();
    } else if(isLoading) {
        return <>
        <TitleHeaderFallback/>
        <ContentPanelWrapper>
            <WideMenu mainCoursePanel={globalCtx.isMainView}/>
            <DataPanelWrapper>
                <CoursePathFallback/>
                {getContentFallbackWithRegardsToUrl()}
            </DataPanelWrapper>
            <CourseNavigationFallback/>
        </ContentPanelWrapper>
    </>
    } 
    else {
        return (
            <CourseContext.Provider value={{
                title: title,
                activeChapter: activeChapter,
                setActiveChapter: setActiveChapter,
                activeAddition: activeAddition,
                setActiveAddition: setActiveAddition,
                path: path,
                handleChapterClick: handleChapterClick,
                videoSrc: videoSrc,
                setVideoSrc: setVideoSrc,
                chapters: chapters,
                setChapters: setChapters,
                currentAssignment: currentAssignment,
                setCurrentAssignment: setCurrentAssignment,
                courseId: courseId,
                getLessonId: getLessonId,
                getBackButton: getBackButton,
                notesEverVisited: notesEverVisited,
                setNotesEverVisited: setNotesEverVisited,
                isLoading: isLoading
            }}>
                {props.children}
            </CourseContext.Provider>
        )
    }
    
}
