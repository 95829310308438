import { Helmet } from "react-helmet"

interface SeoProps {
    title: string;
    description: string;
}

export const Seo = ({title, description}: SeoProps) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}