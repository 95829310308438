import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";

import "./shop.scss";

import { SellingCoursePanel } from "../SellingCoursePanel/SellingCoursePanel";
import { useTranslation } from "react-i18next";
import { CoursePreviewMainPage } from "../../../../shared/coursesPreviewDto";
import { get } from "../../utilities/rest/apiClient";
import { CoursePanelFallback } from "../../components/userPanel/userPanelPages/myCourses/coursePanel/coursePanelFallback/CoursePanelFallback";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";


interface FilterProps {
    name: string,
    isActive: boolean,
    myIndex: number,
    setActiveFilter: Dispatch<SetStateAction<number>>,
    setActivePage: Dispatch<SetStateAction<number>>,
}

const Filter = (props: FilterProps) => {

    const handleClick = () => {
        if(!props.isActive) {

            if(false) {// when filters are disabled
                props.setActiveFilter(props.myIndex);
                props.setActivePage(0);
            }
        }
    }

    return (
        <div 
            className={`shop__body__nav-panel__filters__sections__section not-selectable ${props.myIndex !== 0 ? 'disabled' : 'enabled' }`}
        >
            <div 
                className={`shop__body__nav-panel__filters__sections__section__box${props.isActive ? " shop__body__nav-panel__filters__sections__section__box--active" : "" }`}
                onClick={handleClick}    
            >
                <span className="material-symbols-outlined">
                    {
                        props.isActive ? "check" : ""
                    }
                </span>
            </div>
            <span className="shop__body__nav-panel__filters__sections__section__text">
                { props.name }
            </span>
        </div>
    );
}

export const Shop = () => {
    usePageTracking("shop");
    const { t, i18n } = useTranslation();
    const [activeFilter, setActiveFilter] = useState(0);

    const [filterDisplayed, setFilterDisplayed] = useState(false);

    const [activePage, setActivePage] = useState(0);
    const [displayedCoursesCount, setDisplayedCoursesCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(0); 


    const [coursesData, setCoursesData] = useState<CoursePreviewMainPage[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const filters = [
        t("shop.materials.allOffers"),
        t("shop.materials.separateCourses"),
        t("shop.materials.courseBundles"),
        t("shop.materials.extraMaterials"),
    ];

    const authCtx = useContext(AuthContext);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };


    useEffect(() => {
        if(coursesData.length === 0 || isLoading) return;
    
        function handleResize() {
            let displayedCourses = 0;
    
            if (window.innerWidth <= 1405) {
                displayedCourses = 4;
            } else {
                displayedCourses = 6;
            }
    
            // if(displayedCoursesCount !== displayedCourses) {
                setDisplayedCoursesCount(displayedCourses);
                setPagesCount(Math.ceil(coursesData.length / displayedCourses));
    
                // console.log(`inner width: ${window.innerWidth} HERE: displayedCoursesCnt: ${displayedCourses} pagesCnt: ${pagesCount}`)
            // }
        }

        handleResize();
    
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
          };
    }, [coursesData, isLoading])


    const getCoursesPreviewForTesting = async (url: string, delay: number) => {
        // Tworzymy sztuczne opóźnienie za pomocą Promise i setTimeout
        await new Promise(resolve => setTimeout(resolve, delay));
    
        // Po opóźnieniu wykonujemy rzeczywiste zapytanie
        return get<CoursePreviewMainPage[]>(url);
    };

    useEffect(() => {
        setIsLoading(true);
        if(authCtx.isLoading) return;
        (async ()=> {
            try {
                if(authCtx.loggedIn) {
                    const data = await get<CoursePreviewMainPage[]>(`reactRoutes/getCoursesPreviewForMainPageLoggedUser/${i18n.language}`);

                    setCoursesData(data);

                    setIsLoading(false);
                } else {
                    // console.log("not logged in");
                    const data = await get<CoursePreviewMainPage[]>(`reactRoutes/getCoursesPreviewForMainPage/${i18n.language}`);
                    setCoursesData(data)

                    setIsLoading(false);

                    
                }
              
            } catch(error) {
              console.log(error);
              //TODO other errors
            }
          })()
    }, [authCtx.isLoading]);



    const dynamicFallbackDisplay = () => {

        let displayedCourses = 0;
    
        if (window.innerWidth <= 1405) {
            displayedCourses = 4;
        } else {
            displayedCourses = 6;
        }

        const dummyArray = Array.from({ length: displayedCourses }, (_, index) => index + 1);

        return dummyArray.map((el, index) => <CoursePanelFallback key={index}/> );
    }

    const dynamicCourseDisplay = () => {

        const lowBoundry = activePage * displayedCoursesCount;
        const upperBoundry = lowBoundry + displayedCoursesCount;

        const currentCoursesData = coursesData.slice(lowBoundry, upperBoundry).map((el, index) => 
            <SellingCoursePanel
                key={index}
                first={false}
                last={false}
                courseId={`${el.id}`}
                imgSrc={`${el.imgSrc}`}
                name={el.name}
                nameWithYearUpdated={`${el.name} - ${el.lastUpdate}`}
                description={el.description}
                numberOfHours={el.numberOfHours}
                numberOfLessons={el.numberOfLessons}
                price={el.price}
                lastUpdate={el.lastUpdate.toString()}
                possesion={el.posession}
                coursePubliclyAvailable={el.coursePubliclyAvailable}
            />
        );

        if(currentCoursesData.length < displayedCoursesCount) {
            const missingPanelsCount = displayedCoursesCount - currentCoursesData.length;

            for (let i = 0; i < missingPanelsCount; i++) {
                currentCoursesData.push(<div key={i} className="filler"></div>);
            }
        }

        return currentCoursesData;
    }

    const handleButtonClass = (left: boolean) => {
        const base = 'shop__body__courses__navigation__button';
        const active = left ? (activePage > 0) : (activePage+1 < pagesCount);

        let finalClass = `not-selectable ${base} ${base}--${left ? "left" : "right"}`;
        finalClass += active ? ` ${base}--active` : "";

        return finalClass;
    }

    return(
        <div className="shop">

            <LandingNavbar/>

            <div className="shop__headline">
                <div className="shop__headline__panel">
                    <span>
                        {t("shop.courses")}
                    </span>
                    <span className="shop__headline__panel__colored-text">
                        &nbsp;{t("shop.forStudies")}
                    </span>
                </div>
            </div>

            <div className="shop__body">
                <div className="shop__body__nav-panel">
                    <div className="shop__body__nav-panel__filters">
                        <div className="shop__body__nav-panel__filters__headline">
                            <div className="shop__body__nav-panel__filters__headline__filters">
                                <span>
                                    {t("shop.filters")}
                                </span>
                            </div>
                            <div className="shop__body__nav-panel__filters__headline__reset-filters not-selectable"> 
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                                <span 
                                    className="shop__body__nav-panel__filters__headline__reset-filters__text"
                                    onClick={() => setActiveFilter(0)}
                                >
                                    {t("shop.resetFilters")}
                                </span>
                            </div>

                            <div 
                                className="shop__body__nav-panel__filters__headline__mobile-toggle not-selectable"
                                onClick={() => setFilterDisplayed(!filterDisplayed)}
                            >
                                <span className={`material-symbols-outlined ${filterDisplayed ? 'active' : 'inactive'}`}>
                                    arrow_forward_ios
                                </span>
                            </div>
                        </div>

                        <div className={`shop__body__nav-panel__filters__sections ${(!filterDisplayed) && 'inactive'}`}>
                            <div className="shop__body__nav-panel__filters__sections__headline">
                                <span>
                                    {t("shop.typesOfMaterials")}:
                                </span>
                            </div>

                            {                      
                                filters.map((name, index) => 
                                    <Filter 
                                        key={index} 
                                        name={name}
                                        myIndex={index}
                                        isActive={index === activeFilter}
                                        setActiveFilter={setActiveFilter}
                                        setActivePage={setActivePage}
                                    />)                                        
                            }
                        </div>
                            
                        
                    </div>

                    <div className="shop__body__nav-panel__bundles-info">
                        <div className="shop__body__nav-panel__bundles-info__text-section">
                            <span className="normal">
                                Kupując paczkę oszczędzasz
                            </span>
                            <span className="colored">&nbsp;25%</span>
                        </div>
                        <div className="shop__body__nav-panel__bundles-info__img-section">
                            <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/shop/batch_offer_2.webp" alt=""></img>
                        </div>
                    </div>
                    <div className="shop__body__nav-panel__new-courses-info">
                        <div className="shop__body__nav-panel__new-courses-info__text">
                            <span>Nowe kursy</span>
                            <span> wkrótce</span>
                        </div>
                        <div className="shop__body__nav-panel__new-courses-info__img-section">
                            <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/shop/course_creation.png" alt=""></img>
                        </div>
                    </div>
                </div>

                <div className="shop__body__courses">

                    <div className="shop__body__courses__grid">
                        {
                            isLoading ? dynamicFallbackDisplay() : dynamicCourseDisplay()
                        }

                    </div>
                
                    <div className="shop__body__courses__navigation">
                        <div 
                            className={handleButtonClass(true)}
                                onClick={() => {
                                    if(activePage !== 0) setActivePage(activePage-1);
                                    scrollToTop();
                                } 
                            }
                        >
                            <span className="material-symbols-outlined ">arrow_back_ios</span>
                            <span className="shop__body__courses__navigation__button__text shop__body__courses__navigation__button__text--desktop">
                                {t("shop.previousPage")}
                            </span>
                            <span className="shop__body__courses__navigation__button__text shop__body__courses__navigation__button__text--mobile">
                                {t("shop.previous")}
                            </span>
                        </div>

                        <div className="shop__body__courses__navigation__pages-points">
                            <div className="shop__body__courses__navigation__pages-points__point">
                                <span>{activePage+1}</span>
                            </div>
                            <div className="shop__body__courses__navigation__pages-points__point">
                                <span>{t("shop.of")}</span>
                            </div>
                            <div className="shop__body__courses__navigation__pages-points__point">
                                <span>{pagesCount}</span>
                            </div>
                        </div>

                        <div 
                            className={handleButtonClass(false)}
                            onClick={
                                () => {
                                    if(activePage+1 !== pagesCount) setActivePage(activePage+1);
                                    scrollToTop();
                                } 
                            }
                        >
                            <span className="shop__body__courses__navigation__button__text shop__body__courses__navigation__button__text--desktop">
                                {t("shop.nextPage")}
                            </span>
                            <span className="shop__body__courses__navigation__button__text shop__body__courses__navigation__button__text--mobile">
                                {t("shop.next")}
                            </span>
                            <span className="material-symbols-outlined">arrow_forward_ios</span>
                        </div>
                    </div>
                </div>

                <div className="shop__body__new-courses-info-mobile">
                        <div className="shop__body__new-courses-info-mobile__text">
                            <span>Nowe kursy</span>
                            <span> wkrótce</span>
                        </div>
                        <div className="shop__body__new-courses-info-mobile__img-section">
                            <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/shop/course_creation.png" alt=""></img>
                        </div>
                </div>
                
            </div>

            <LandingFooter/>
        </div>
        
    )
}