
import { Dispatch, SetStateAction, useState } from "react";
import { DisplayedCourseDescription } from "../DisplayedCourseDescription/DisplayedCourseDescription";
import { Block } from "../../../../../shared/block";
import { useTranslation } from "react-i18next";

interface DisplayedOptionProps {
    text: string,
    myIndex: number,
    tableOfContentsRef: HTMLDivElement | null;
    isActive: boolean;
}

const DisplayedOption = (props: DisplayedOptionProps) => {

    const handleClick = () => {
        if(props.tableOfContentsRef === null) return;

        props.tableOfContentsRef.scrollIntoView({ behavior: 'smooth' });
    }
    
    const handleClassName = () => {
        const base = "course-preview__main-section__left-panel__panels-selector__option";
        let finalClass = `not-selectable ${base} ${base}--${(props.isActive === true) ? "active" : "inactive"}`;

        return finalClass;
    }

    return (
        <div 
            onClick={() => { 
                    handleClick()
            }}
            className={handleClassName()}
        >
            <span>{props.text}</span>
        </div>
    );
}  

interface CourseContentWrapperProps {
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[];

    activeSectionIndex: number;
    setActiveSectionIndex?: Dispatch<SetStateAction<number>>;
    description: Block[];
    courseRecomendation: Block[];
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];

    fromLanding: boolean;

    courseName: string;
}

export const CourseSectionsWrapper = (props: CourseContentWrapperProps) => {
    const [tableOfContentsRef, setTableOfContentsRef] = useState<HTMLDivElement | null>(null);
    const [aboutAuthorsRef, setAboutAuthorsRef] = useState<HTMLDivElement | null>(null);

    return (

        <>
            {
                (!props.fromLanding) && 
                <div className="course-preview__main-section__left-panel__panels-selector">
                    <DisplayedOption 
                        myIndex={0}
                        text={"Opis Kursu"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={true}
                    />
                    <DisplayedOption 
                        myIndex={1}
                        text={"Spis Treści"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={false}

                    />
                    <DisplayedOption 
                        myIndex={2}
                        text={"O Autorach"}
                        tableOfContentsRef={aboutAuthorsRef}
                        isActive={false}

                    />
                </div>
            }
            <DisplayedCourseDescription
                    description={props.description}
                    courseContent={props.courseContent}
                    publiclyAvailableChapter={props.publiclyAvailableChapter} 
                    publiclyAvailableLessons={props.publiclyAvailableLessons}
                    setTableOfContentsRef={setTableOfContentsRef}
                    setAboutAuthorsRef={setAboutAuthorsRef}
                    fromLanding={props.fromLanding}
                    courseName={props.courseName}
                />
        </>
    )
}