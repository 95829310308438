import { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";

export const usePageTracking = (title: string) => {

  const authContext = useContext(AuthContext);


  useEffect(() => {

    if(authContext.allowedCookies.fromBrowser && authContext.allowedCookies.statCookies) {

      const currentLocation = window.location.pathname + window.location.search;

      
      ReactGA.send({ hitType: "pageview", page: currentLocation, title: title });
    }
  }, [authContext.allowedCookies])
  
};