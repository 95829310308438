import * as React from "react"

export const GoBackArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="##1a1a21"
    viewBox="0 -960 960 960"
  >
    <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
  </svg>
)

