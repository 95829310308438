import { Navigate, useNavigate } from "react-router-dom";
import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { ArrowGraphic } from "../MainPage/graphics/ArrowGraphic";

import "./documents.scss";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";

export const WebsiteRegulations = () => {
  usePageTracking("regulation");
  const navigate = useNavigate();

  return (
    <div className="document">
      <LandingNavbar />

      <div className="document__text">
        <div>
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            <strong>
              <span>REGULAMIN SERWISU NASESJE.COM</span>
            </strong>
          </p>
          <p style={{ textAlign: "center" }}>
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "7.5pt",
              marginBottom: "7.5pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <em>
                <span>Wstęp</span>
              </em>
            </strong>
          </p>
          <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginTop: "14pt",
                marginLeft: "14.2pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.13pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Niniejszy Regulamin określa zasady korzystania z usług Serwisu
                Nasesje.com przez jego Użytkowników.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.2pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.13pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Usługi Serwisu świadczone są przez ELERNA GROUP Spółka z
                ograniczoną odpowiedzialnością z siedzibą w Krakowie.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.2pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.13pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Usługodawca może ustalać szczegółowe zasady oraz zakres
                świadczenia poszczególnych Usług w dodatkowych regulaminach.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.2pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.13pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Regulamin może zostać zaktualizowany, o czym Użytkownicy zostaną
                każdorazowo poinformowani na stronie internetowej serwisu
                Nasesje.com.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.2pt",
                marginBottom: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.13pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Regulamin jest dostępny na stronie internetowej Serwisu
                Nasesje.com tj. pod adresem Nasesje.com/regulamin i jest
                każdorazowo udostępniany Użytkownikowi przed zawarciem Umowy.
                Przed zawarciem Umowy Użytkownik zobowiązany jest potwierdzić,
                że zapoznał się z jego treścią.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <div className="spacer" />
            <strong>
              <span>§ 1 - Definicje</span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              fontSize: "16px",
            }}
          >
            <span>Użyte w niniejszym Regulaminie określenia oznaczają: </span>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Administrator Serwisu
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - właściciel Serwisu lub osoba posiadająca upoważnienie do
                zarządzania Serwisem,
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Dane Osobowe{" "}
              <span style={{ fontWeight: "normal" }}>
                - zgodnie z art. 4 pkt 1) RODO, informacje o zidentyfikowanej
                lub możliwej do zidentyfikowania osobie fizycznej ("osobie,
                której dane dotyczą"); możliwa do zidentyfikowania osoba
                fizyczna to osoba, którą można bezpośrednio lub pośrednio
                zidentyfikować, w szczególności na podstawie identyfikatora
                takiego jak imię i nazwisko, numer identyfikacyjny, dane o
                lokalizacji, identyfikator internetowy lub jeden bądź kilka
                szczególnych czynników określających fizyczną, fizjologiczną,
                genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną
                tożsamość osoby fizycznej. Dane Osobowe dzielą się na Dane
                Osobowe Szczególnych Kategorii Danych oraz Dane Osobowe zwykłe.
                O nadaniu zbiorowi/zestawowi danych kwalifikacji Danych
                Osobowych decyduje połączenie z danymi identyfikatora (np. numer
                ewidencyjny PESEL) umożliwiającego skojarzenie danych{" "}
              </span>
              <br />
              <span style={{ fontWeight: "normal" }}>
                z konkretną osobą fizyczną. Jednocześnie usunięcie ze zbioru
                danych wszystkich identyfikatorów skutkuje pozbawieniem danych
                kwalifikacji Danych Osobowych,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Formularz zamówienia
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - interaktywny formularz dostępny w Serwisie umożliwiający
                złożenie Zamówienia,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Klient{" "}
              <span style={{ fontWeight: "normal" }}>
                - osoba fizyczna, składająca Zamówienie w ramach Serwisu, która
                posiada pełną zdolność do czynności prawnych lub zgodę opiekuna
                prawnego na dokonanie zakupu danego Produktu w Serwisie
                (zawarcie umowy sprzedaży) oraz na przetwarzanie jej danych
                osobowych w ramach Konta Użytkownika i korzystania z Serwisu w
                przypadku, gdy nie ukończyła 16 roku życia,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Konto Użytkownika
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - funkcjonalność dostępna dla Użytkownika po rejestracji w
                Serwisie zgodnie z postanowieniami Regulaminu (po dokonaniu
                rejestracji Konto dostępne jest po każdorazowym zalogowaniu w
                Serwisie) i dająca możliwość skorzystania z określonych usług
                Serwisu,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Konsument
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - Klient lub Użytkownik będący osobą fizyczną mający pełną
                zdolność do czynności prawnych, dokonujący u Sprzedawcy zakupu
                Produktów, niezwiązanego bezpośrednio z jego działalnością
                gospodarczą lub zawodową, zgodnie z ustawą z 30 maja 2014 r. o
                prawach konsumenta,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Produkt{" "}
              <span style={{ fontWeight: "normal" }}>
                - produkty lub materiały elektroniczne zakupione lub dostępne w
                Sklepie. Produkty są sprzedawane odpłatnie, chyba że wyraźnie
                zastrzeżono inaczej,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Serwis -
              <span style={{ fontWeight: "normal" }}>
                {" "}
                serwis internetowy prowadzony pod adresem internetowym
                Nasesje.com. wraz z jego subdomenami,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Sklep internetowy
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - serwis internetowy dostępny pod adresem Nasesje.com/shop za
                pośrednictwem którego Klient może złożyć Zamówienie,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Sprzedawca
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - ELERNA GROUP Spółka z ograniczoną odpowiedzialnością (ul.
                Chmieleniec{" "}
              </span>
              <br />
              <span style={{ fontWeight: "normal" }}>
                nr 6 lok. 9, 30 - 348 Kraków), wpisana do rejestru
                przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy
                dla Krakowa - Śródmieścia w Krakowie, Wydział XI Gospodarczy
                Krajowego Rejestru Sądowego pod numerem KRS: 0001098308, NIP:
                6762666516, REGON: 528290584,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Produkt{" "}
              <span style={{ fontWeight: "normal" }}>
                - produkty lub materiały elektroniczne zakupione lub dostępne w
                Sklepie. Produkty są sprzedawane odpłatnie, chyba że wyraźnie
                zastrzeżono inaczej.
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Regulamin{" "}
              <span style={{ fontWeight: "normal" }}>
                - niniejszy dokument, którego aktualna treść znajduje się pod
                adresem: Nasesje.com/regulamin.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              RODO -
              <span style={{ fontWeight: "normal" }}>
                {" "}
                rozporządzenie Parlamentu Europejskiego i Rady(UE)2016/679 z
                dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych),{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Umowa sprzedaży
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - umowa sprzedaży Produktów w rozumieniu Kodeksu Cywilnego,
                zawarta pomiędzy Sprzedawcą, a Klientem, zawierana z
                wykorzystaniem Sklepu internetowego,
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Ustawa o prawach konsumenta
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - ustawa z dnia 30 maja 2014 r. o prawach konsumenta (Dz.U. z
                2023 r. poz. 2759),{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Użytkownik
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - osoba korzystająca z Serwisu i treści umieszczonych w
                Serwisie, której został przyznany dostęp do Serwisu przez
                Administratora Serwisu,
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Zamówienie
              <span style={{ fontWeight: "normal" }}>
                {" "}
                - oświadczenie woli Klienta złożone za pośrednictwem Serwisu
                określające jednoznacznie: rodzaj i ilość Produktów i/lub usług,
                rodzaj płatności, dane Klienta, które zmierzają bezpośrednio do
                zawarcia umowy sprzedaży pomiędzy Konsumentem a Sprzedawcą.
              </span>
              <br />
            </li>
          </ol>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 2 - Zasady Korzystania z Serwisu</span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Serwis przeznaczony jest dla Użytkowników, którymi mogą być
                wyłącznie osoby fizyczne. Użytkownicy mogą korzystać z Usług,
                świadczonych za pośrednictwem Serwisu, tylko w celach
                prywatnych.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik oświadcza, że wszelkie informacje rejestracyjne i
                dane osobowe, które przekazuje Administratorowi Serwisu, są
                prawdziwe, dokładne i kompletne oraz zobowiązuje się, by takie
                pozostawały przez cały czas obowiązywania Umowy.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik nie może wykorzystywać Serwisu i informacji o
                dostępności Produktów do prowadzenia jakiejkolwiek działalności
                sprzecznej z powszechnie obowiązującym prawem.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik przed skorzystaniem z usług Serwisu oświadcza, że
                zapoznał się z Regulaminem oraz akceptuje jego warunki.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu przetwarza należne płatności za
                pośrednictwem ELERNA GROUP Spółka z ograniczoną
                odpowiedzialnością z siedzibą w Krakowie (ul. Chmieleniec nr 6
                lok. 9, 30 - 348 Kraków), wpisanej do rejestru przedsiębiorców
                Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Krakowa
                -Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego
                Rejestru Sądowego pod numerem KRS: 0001098308, NIP: 6762666516,
                REGON: 528290584,
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Do złożenia zamówienia za pośrednictwem Serwisu oraz korzystania
                z niego konieczne jest posiadanie urządzenia z dostępem do
                Internetu umożliwiające przeglądanie stron internetowych, a
                także klawiatury lub innego urządzenia umożliwiającego poprawne
                wypełnienie formularzy elektronicznych. Zaleca się korzystanie z
                najnowszych wersji przeglądarek internetowych: Firefox, Chrome,
                Internet Explorer, Microsoft Edge, Opera, Safari itd.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Strona Serwisu Nasesje.com posiada certyfikat bezpieczeństwa -
                bezpieczny protokół szyfrowania komunikacji.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Zabronione jest udostępnianie danych Konta Użytkownika osobom
                trzecim oraz zakładanie kilku Kont przez jednego Użytkownika.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu może wypowiedzieć umowę o świadczenie
                usługi elektronicznej{" "}
              </span>
              <br />
              <span style={{ fontWeight: "normal" }}>
                i usunąć Konto Użytkownika lub pozbawić go prawa do składania
                Zamówień, ze skutkiem natychmiastowym, z ważnych powodów, w
                przypadku istotnego i rażącego naruszenia przez niego
                postanowień niniejszego Regulaminu, tj. w szczególności w
                przypadku, gdy korzysta on z Serwisu i Konta w sposób niezgodny
                z przepisami prawa lub postanowieniami Regulaminu i niezgodnie z
                dobrymi obyczajami oraz przeznaczeniem Konta, w sposób uciążliwy
                dla innych Klientów oraz dla Administratora Serwisu, kopiuje
                Produkty Administratora Serwisu lub udostępnia je podmiotom
                trzecim bez zgody Administratora, podaje mu dane niezgodne z
                rzeczywistym stanem prawnym, nieprawidłowe, niedokładne lub
                naruszające prawa osób trzecich, lub też narusza lub próbuje
                naruszyć zabezpieczenia techniczne Serwisu i Konta w celu
                niedozwolonego uzyskania dostępów do jego zasobów.
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W związku z usunięciem Konta przez Administratora Serwisu
                Klient/Użytkownik traci dostęp do wszystkich Produktów i zasobów
                dostępnych uprzednio na jego Koncie.
              </span>
            </li>
            <li
              style={{
                marginLeft: "20.58pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik zobowiązuje się do korzystania z Serwisu w
                odpowiedni, zgodny z prawem oraz gwarantuje, że nie naruszy
                właściwego miejscowego, stanowego, krajowego ani
                międzynarodowego prawa, ani żadnych praw osób trzecich (z
                uwzględnieniem znaków towarowych, tajemnicy handlowej, praw
                autorskich oraz innych praw własności). W szczególności
                Użytkownik zobowiązuje się nie korzystać z Serwisu w celu:
              </span>
              <ol
                className="awlist2"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.77pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ładowania, publikowania, przesyłania za pomocą wiadomości
                    e-mail, transmisji lub udostępniania w jakikolwiek inny
                    sposób Treści Użytkownika niezgodnych z prawem, szkodliwych,
                    stanowiących zagrożenie, obraźliwych, prześladowczych,
                    zniesławiających, wulgarnych, nieprzyzwoitych, oszczerczych,
                    naruszających prywatność innej osoby, pełnych nienawiści
                    bądź niewłaściwych w kontekście rasowym, etnicznym lub
                    innym,
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.16pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    szkodzenia osobom nieletnim w jakikolwiek sposób,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.5pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    podszywania się pod jakąkolwiek osobę lub jednostkę, w tym
                    między innymi pracownika Administratora Serwisu lub
                    składania nieprawdziwych oświadczeń bądź przedstawiania w
                    niewłaściwy sposób powiązań z jakąkolwiek osobą lub
                    jednostką,
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.09pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    korzystania z Serwisu w celu przekierowania do innej strony
                    internetowej,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.82pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    nagrywania i publikowania jakichkolwiek treści zawartych w
                    Serwisie osobom trzecim,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "6.07pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ładowania, publikowania, przesyłania za pomocą wiadomości
                    e-mail, transmisji lub udostępniania w jakikolwiek inny
                    sposób Treści Użytkownika, których nie mogą Państwo
                    udostępniać na mocy jakiegokolwiek prawa bądź stosunków
                    umownych lub powierniczych (takich jak informacje
                    wewnętrzne, zastrzeżone oraz poufne, w posiadaniu których
                    znaleźli się Państwo w ramach wykonywania obowiązków
                    służbowych),{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.94pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ładowania, publikowania, przesyłania za pomocą wiadomości
                    e-mail, transmisji lub udostępniania w jakikolwiek inny
                    sposób Treści Użytkownika, które naruszają Prawa własności
                    intelektualnej lub prawa do zachowania poufności osób
                    trzecich,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.1pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ładowania, publikowania, przesyłania za pomocą wiadomości
                    e-mail, transmisji lub udostępniania w jakikolwiek inny
                    sposób niechcianych lub nieautoryzowanych treści
                    reklamowych, materiałów promocyjnych, „wiadomości-śmieci”,
                    „spamu”, „łańcuszków”, „piramid finansowych” i innych form
                    pozyskiwania klientów,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "6.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ładowania, publikowania, przesyłania za pomocą wiadomości
                    e-mail, transmisji lub udostępniania w jakikolwiek inny
                    sposób materiałów zawierających wirusy lub innego rodzaju
                    kody komputerowe, pliki lub programy stworzone w celu
                    przerwania, zniszczenia lub ograniczenia funkcjonowania
                    oprogramowania komputerowego, sprzętu lub sprzętu
                    telekomunikacyjnego,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "6.34pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    ingerowania lub przerywania ciągłości działania Serwisu,
                    serwerów lub sieci podłączonych do Serwisu bądź
                    nieprzestrzegania wymagań, procedur, polityk lub regulacji
                    dotyczących sieci podłączonych do Serwisu,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "3.15pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    „śledzenia” lub nękania innych Użytkowników w inny sposób,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-3pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "6.27pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    gromadzenia lub przechowywania danych osobowych innych
                    Użytkowników w związku z zachowaniami i działaniami
                    zabronionymi w powyższych paragrafach, a także promowania
                    innych stron lub usług niezwiązanych z usługami oferowanymi{" "}
                  </span>
                  <span style={{ fontWeight: "normal", color: "#0c1c44" }}>
                    przez Serwis.
                  </span>
                </li>
              </ol>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "20.58pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.72pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W przypadku wykrycia przez Administratora Serwisu,
                niedozwolonych niniejszym Regulaminem zachowań Użytkownika, w
                szczególności polegających na udostępnianiu przez Użytkownika
                osobom trzecim jakichkolwiek Treści Płatnych znajdujących się w
                Serwisie, Administrator Serwisu ma prawo dochodzić od
                Użytkownika stosownego odszkodowania stanowiącego iloczyn ilości
                nieuprawnionych odtworzeni oraz cen Produktów.{" "}
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 3 - Składanie Zamówień w Serwisie i ich realizacja</span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Ceny wszystkich usług oferowanych przez Sklep są cenami brutto
                (zawierają podatek VAT) oraz są wyrażone w złotych polskich.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W ramach funkcjonowania Serwisu dostępne są dwa typy materiałów
                i produktów:
              </span>
            </li>
          </ol>
          <ol className="awlist3" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.77pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                bezpłatne - takie, których zamówienia może dokonać nieodpłatnie
                każdy Użytkownik, posiadający aktywne Konto Użytkownika,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.16pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                płatne - dostępne wyłącznie dla Użytkowników, którzy dokonali w
                Serwisie zakupu określonego Produktu.
              </span>
            </li>
          </ol>
          <ol start={3} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Warunkiem uzyskania dostępu do materiałów i produktów
                bezpłatnych jest wyrażenie przez Użytkownika odpowiednich zgód
                marketingowych.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Dostęp do materiałów i produktów płatnych w Serwisie można
                uzyskać jedynie bezpośrednio w Serwisie poprzez wypełnienie
                Formularza zamówienia (po dokonaniu wyboru Produktu, podejmując
                czynności techniczne w oparciu o wyświetlane Użytkownikowi
                komunikaty i informacje zawarte w Serwisie) oraz dokonanie
                opłaty w wysokości określonej dla danego Produktu.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Wypełniając Formularz zamówienia w Serwisie po raz pierwszy,
                Użytkownik automatycznie dokonuje rejestracji Konta Użytkownika.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Rejestracji Użytkownika można dokonać również przed wypełnieniem
                Formularza zamówienia, co jest dla Użytkownika darmowe i odbywa
                się poprzez naciśnięcie przycisku “Załóż darmowe konto” i
                wypełnienie danych personalnych i danych do logowania.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Logowanie się do Konta Użytkownika odbywa się poprzez podanie
                loginu (adresu e-mail){" "}
              </span>
              <br />
              <span style={{ fontWeight: "normal" }}>
                i hasła podanych jako dane do logowania.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik ma możliwość w każdej chwili, bez podania przyczyny i
                bez ponoszenia z tego tytułu jakichkolwiek opłat usunąć Konto
                Użytkownika za pośrednictwem panelu “Moje konto”. W przypadku
                usunięcia Konta, Klient przyjmuje do wiadomości, iż utraci
                bezpowrotnie dostęp do wszelkich, nabytych i zapisanych w Koncie
                Użytkownika Produktów.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Nazwa Użytkownika i hasło powiązane z Kontem Użytkownika są
                przeznaczone wyłącznie do użytku osobistego i powinny być
                traktowane jako Informacje Poufne. Użytkownik oświadcza, że
                odpowiada za każde użycie (w tym każde nieuprawnione użycie)
                nazwy jego użytkownika{" "}
              </span>
              <br />
              <span style={{ fontWeight: "normal" }}>
                oraz hasła. W przypadku utraty lub kradzieży nazwy użytkownika
                lub hasła, bądź w przypadku podejrzenia nieautoryzowanego
                dostępu do konta należy natychmiast powiadomić Dział Obsługi
                Klienta Serwisu pod adresem: support@nasesje.com
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 4 - Zawarcie Umowy Sprzedaży</span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik podczas wypełniania formularza zamówienia Produktu
                jest zobowiązany do podania prawidłowych danych osobowych i
                danych płatności, w szczególności: imienia i nazwiska, adresu
                e-mail, danych karty płatniczej itp.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Do prawidłowego zakończenia procedury Zamówienia niezbędna jest
                akceptacja niniejszego Regulaminu oraz Polityki prywatności
                Serwisu. W przypadku dokonywania Zamówienia niektórych
                Produktów, za wyraźnym wskazaniem Sprzedawcy, może być konieczna
                akceptacja dodatkowych postanowień i/lub odrębnych regulaminów
                precyzujących zasady nabycia danego Produktu/usługi cyfrowej.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Proces składania Zamówienia kończy się w chwili zaksięgowania na
                koncie Sprzedawcy wpłaty należnej za zamówiony Produkt.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Płatności za zamówiony Produkt Klient dokonuje z góry za
                pośrednictwem udostępnionych przez Administratora Serwisu metod
                płatności (np. ApplePay, Blik, karty płatniczej za pośrednictwem
                Stripe’a).
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Utrwalenie, udostępnienie, zabezpieczenie i potwierdzenie
                Klientowi istotnych postanowień Umowy sprzedaży następuje
                natychmiast po zaksięgowaniu na koncie Sprzedawcy wpłaty
                należnej za zamówiony Produkt poprzez przesłanie Klientowi na
                adres poczty elektronicznej potwierdzenia Zamówienia oraz
                nadanie dostępu do zamówionych Treści płatnych, z zastrzeżeniem
                ust. 6 poniżej.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W przypadku niektórych Produktów, dostęp do Treści płatnych może
                być udzielany czasowo (np. na okres kursu trwającego 8 miesięcy
                od dnia zawarcia Umowy), zgodnie z harmonogramem i terminami
                wskazanymi w ofercie danego Produktu, zaprezentowanej przez
                Sprzedawcę.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Dostawa zamówionych Produktów elektronicznych (usług cyfrowych)
                odbywa się nieodpłatnie, w ramach usługi utrzymania Konta
                Użytkownika.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Sprzedawca zastrzega sobie prawo do niezrealizowania Zamówienia
                w przypadku:
              </span>
              <ol
                className="awlist4"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "5.42pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    nieprawidłowego lub niekompletnego wypełnienia formularza
                    Zamówienia (brak wszystkich danych potrzebnych do realizacji
                    Zamówienia),{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "17pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.81pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    nieotrzymania wpłaty w terminie 14 dni od złożenia
                    Zamówienia (w przypadku wyboru opcji płatności
                    elektronicznej i niedokończenia tej płatności).{" "}
                  </span>
                </li>
              </ol>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Otrzymanie faktury VAT do złożonego przez Klienta Zamówienia,
                możliwe jest poprzez przesłanie wiadomości e-mailowej do
                Sprzedawcy na adres: support@nasesje.com
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 5 - Prawo odstąpienia od Umowy</span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Prawo odstąpienia od Umowy zawartej poza lokalem
                przedsiębiorstwa lub na odległość nie przysługuje Konsumentowi w
                odniesieniu do Produktów Sprzedawcy realizowanych w ramach umów:
              </span>
            </li>
          </ol>
          <ol className="awlist5" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.58pt",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              dostarczanie treści cyfrowych (materiałów audiowizualnych np.
              kursów online, dostępów do nagrań, innych etc.) niedostarczanych
              na nośniku materialnym, za które Konsument jest zobowiązany do
              zapłaty ceny, jeżeli Serwis rozpoczął świadczenie za wyraźną i
              uprzednią zgodą Konsumenta, który został poinformowany przed
              rozpoczęciem świadczenia, że po spełnieniu świadczenia przez
              Serwis utraci prawo odstąpienia. Powyższą zgodę Konsument może
              wyrazić podczas procesu zakupowego w postaci złożenia, w formie
              elektronicznej, osobnego oświadczenia woli w tym zakresie,
              następującego poprzez zaznaczenie odpowiedniego pola wyboru
              (checkbox) dotyczącego Obowiązków informacyjnych Sprzedawcy,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.93pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              świadczenie usług, za które Konsument jest zobowiązany do zapłaty
              ceny, jeżeli Serwis&nbsp;wykonał w pełni usługę za wyraźną i
              uprzednią zgodą Konsumenta, który został poinformowany przez
              Serwis przed rozpoczęciem świadczenia, że po spełnieniu
              świadczenia przez Serwis&nbsp;&nbsp;utraci prawo odstąpienia od
              umowy i przyjął to do wiadomości. Powyższą zgodę Konsument może
              wyrazić podczas procesu zakupowego w postaci złożenia, w formie
              elektronicznej, osobnego oświadczenia woli w tym zakresie,
              następującego poprzez zaznaczenie odpowiedniego pola wyboru
              (checkbox) dotyczącego Obowiązków informacyjnych Sprzedawcy.
            </li>
          </ol>
          <ol start={2} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "6pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W pozostałych przypadkach, w których Użytkownikowi przysługuje
                prawo odstąpienia od umowy, do zachowania terminu wystarczy
                wysłanie oświadczenia przed jego upływem. Oświadczenie o
                odstąpieniu od umowy musi zostać złożone drogą poczty
                elektronicznej (e-mailowo) na adres: support@nasesje.com
              </span>
              <br/><br/>
              <span style={{ fontWeight: "normal" }}>
                W ramach "7-dniowej gwarancji satysfakcji" możliwość odstąpienia od umowy przysługuje Użytkownikowi w przeciągu 7 dni od dokonania zakupu, pod warunkiem że w tym czasie nie obejrzał więcej niż 30% lekcji dostępnych w kursie. Zwrot środków nastąpi po weryfikacji spełnienia powyższych warunków. Oświadczenie o
                odstąpieniu od umowy musi zostać złożone drogą poczty
                elektronicznej (e-mailowo) na adres: support@nasesje.com
              </span>
              <br /><br/><br/>
            </li>
          </ol>
          <p
            className="ListParagraph"
            style={{
              marginLeft: "14.2pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <em>
              <span>
                W oświadczeniu o odstąpieniu od umowy należy podać następujące
                dane:
              </span>
            </em>
          </p>
          <ol className="awlist6" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.58pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              Imię i nazwisko Użytkownika,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.93pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              adres e-mail powiązany z Kontem Użytkownika,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "5.1pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              nazwę produktu,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.85pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              numer rachunku bankowego do zwrotu środków,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.85pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              powód odstąpienia (w przypadku "7-dniowej gwarancji satysfakcji"),
            </li>
          </ol>
          <ol start={3} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "6pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W przypadku opisanym w ust. 2 powyżej, Sprzedawca ma obowiązek
                niezwłocznie, nie później niż w terminie 14 dni od otrzymania
                oświadczenia Klienta o odstąpieniu od umowy, zwrócić mu
                wszystkie dokonane przez niego płatności.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 6 - Ochrona danych osobowych </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Informacje dotyczące ochrony danych Użytkownika znajdują się w
              Polityce Prywatności Serwisu, która stanowi załącznik do
              niniejszego Regulaminu i której aktualna wersja jest dostępna na
              stronie: Nasesje.com/politykaPrywatnosci.
            </span>
          </p>
          <p
            style={{
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 7 - Prawa autorskie</span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Wszelkie treści dostępne w Serwisie, materiały elektroniczne i
                Produkty oraz usługi świadczone przez Administratora Serwisu,
                dostępne w Serwisie, mogą stanowić utwory w rozumieniu Ustawy z
                dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych,
                podlegają zatem ochronie prawnej i stanowią własność
                intelektualną Administratora Serwisu lub podmiotów trzecich
                tworzących je w ramach Serwisu.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu udziela Użytkownikowi licencji
                niewyłącznej i nieprzenoszalnej, bez prawa do udzielania
                sublicencji na korzystanie z materiałów umieszczonych w ramach
                Serwisu. Użytkownik w ramach Serwisu upoważniony jest do
                korzystania z zasobów Serwisu jedynie na własne potrzeby, bez
                ograniczeń terytorialnych, na następujących polach eksploatacji:
              </span>
            </li>
          </ol>
          <ol className="awlist7" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.63pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              utrwalenie poprzez cyfrowe przetwarzanie tylko na dedykowanej
              platformie (Koncie Klienta) utrzymywanej przez Administratora
              Serwisu,
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.98pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              zapisywanie w formie cyfrowej lub wydruk materiałów w formacie
              pdf. lub w formie edytowalnej jedynie na własne potrzeby.
            </li>
          </ol>
          <ol start={3} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Licencja, o której mowa w ust. 2 powyżej jest ważna przez czas
                trwania dostępu do Treści płatnych w Serwisie i udzielona jest
                odpłatnie.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Zakazane jest w szczególności, zarówno co do całości zasobów
                Serwisu, jak i ich części:
              </span>
            </li>
          </ol>
          <ol className="awlist8" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.82pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                udostępnianie i prezentowanie zasobów Serwisu osobom trzecim,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "3.21pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                publikowanie zasobów Serwisu niezależnie od formy publikacji,{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "32pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.55pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                kopiowanie, powielanie na potrzeby inne niż własny użytek.
              </span>
            </li>
          </ol>
          <ol start={5} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu informuje niniejszym Użytkownika, że
                jakiekolwiek rozpowszechnianie zasobów Serwisu lub jakichkolwiek
                innych treści udostępnionych przez Administratora Serwisu
                stanowi naruszenie przepisów prawa i może rodzić
                odpowiedzialność cywilną i/lub karną. Administrator Serwisu może
                domagać się także stosownego odszkodowania i/lub
                zadośćuczynienia z tytułu poniesienia strat materialnych lub
                niematerialnych zgodnie z obowiązującymi przepisami prawa.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W przypadku zamiaru wykorzystania zasobów Serwisu w sposób
                sprzeczny z wskazanym w niniejszym Regulaminie i treści
                udzielonej licencji, Użytkownik obowiązany jest uzyskać pisemną
                zgodę Administratora Serwisu.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.5pt",
              marginBottom: "7.5pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 8 - Reklamacje </span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik może złożyć reklamację dotyczącą Usług świadczonych w
                ramach Serwisu.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Reklamację można złożyć w formie elektronicznej, przesyłając
                wiadomość z imieniem, nazwiskiem oraz opisem zdarzenia będącego
                przyczyną reklamacji na adres e-mail: support@nasesje.com.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Serwis rozpatruje reklamacje w terminie do 14 dni od daty
                otrzymania i udziela odpowiedzi w formie elektronicznej na adres
                e-mail z którego dokonano zgłoszenia reklamacyjnego.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu oświadcza, iż będzie podejmował starania
                mające na celu zapewnienie należytej jakości świadczenia usług i
                bez zbędnej zwłoki będzie usuwał zauważone lub zgłoszone błędy w
                działaniu Serwisu.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Użytkownik ma prawo zgłaszać Administratorowi Serwisu informacje
                o błędach, awariach oraz inne uwagi, dotyczące funkcjonowania
                Serwisu i mogące poprawić jakość świadczonych, na adres e-mail:
                support@nasesje.com.
              </span>
            </li>
          </ol>
          <p
            style={{
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>§ 9 - Postanowienia końcowe i dane kontaktowe </span>
            </strong>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginTop: "14pt",
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                W zakresie nieuregulowanym w niniejszym Regulaminie zastosowanie
                znajdują przepisy powszechnie obowiązującego prawa, w tym
                przepisy Kodeksu cywilnego oraz Ustawy o prawach konsumenta.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Administrator Serwisu zastrzega sobie prawo zmiany Regulaminu w
                każdym czasie.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Wszelkie uwagi, komentarze, pytania lub nieprawidłowości
                związane z działaniem serwisu Użytkownik może kierować na adres
                e-mail: support@nasesje.com
              </span>
            </li>
            <li
              style={{
                marginLeft: "14.07pt",
                marginBottom: "14pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "0.13pt",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                Aktualna treść Regulaminu jest zawsze dostępna dla Użytkownika
                na stronie internetowej Serwisu Nasesje.com.
              </span>
            </li>
          </ol>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>Regulamin wchodzi w życie dnia: 14.04.2024 r.</span>
            </strong>
          </p>

          <div className="spacer" />

          <p style={{ lineHeight: "150%", fontSize: "18px" }}>
            <strong>
              <span>ZAŁĄCZNIKI DO REGULAMINU:</span>
            </strong>
          </p>
          <div className="links">
            <p
                onClick={() => navigate("/politykaPrywatnosci")}
                style={{
                    lineHeight: "150%",
                    fontSize: "16px",
                    fontStyle: "normal",
                }}
            >
                <ArrowGraphic/>
                <span>Polityka Prywatności</span>
            </p>
            <p
              onClick={() => navigate("/obowiazekInformacyjny")} 
              style={{ lineHeight: "150%", fontSize: "16px" }}
            >
                <ArrowGraphic/>
                <span>Obowiązek informacyjny dla Konsumentów.</span>
            </p>
          </div>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>&nbsp;</span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>&nbsp;</span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          ></p>
        </div>
      </div>

      <LandingFooter />
    </div>
  );
};