
import { useState } from "react";
import { CloseIcon400 } from "../../Icons/CloseIcon400";
import { InfoIcon400 } from "../../Icons/InfoIcon400";
import "./guarantee.scss";
import { CheckIcon400 } from "../../Icons/CheckIcon400";

import { AuthContext, AuthContextProps } from "../../../components/auth/authContext/AuthContextProvider";
import { registerEvent } from "../../../hooks/analytics/registerEvent";

interface GuaranteeProps {
    authContext: AuthContextProps;
    component: 'main page' | 'course preview';
}

export const Guarantee = (props: GuaranteeProps) => {

    // const [displayGuarantee, setDisplayGuarantee] = useState(true);
    const getMainContainerClass = () => {
        if(props.component === 'main page') {
            return "main-page__guarantee";
        } else if (props.component === 'course preview') {
            return "main-page__guarantee coursePreview";
        } else {
            return "";
        }
    }

    return (
        <>

            <div className={getMainContainerClass()}>
                <div 
                    // onClick={() => setDisplayGuarantee(false)}
                    className="main-page__guarantee__sign">
                    

                    {/* <CloseIcon400/> */}
                    <CheckIcon400/>
                </div>
                <div className="main-page__guarantee__money">
                    <span>💸</span>
                </div>
                <div className="main-page__guarantee__text">
                    <span className="main-page__guarantee__text__headline desktop">
                        7-dniowa gwarancja satysfakcji
                    </span>
                    <span className="main-page__guarantee__text__headline mobile">
                        7-dniowa gwarancja
                    </span>
                    <span  className="main-page__guarantee__text__description desktop">
                        Jeśli kurs nie spełni twoich oczekiwań, do 7 dni możesz dokonać zwrotu &nbsp;
                        <b>zwrócimy ci pieniądze <InfoIcon400/></b>.
                    </span>
                    <span  className="main-page__guarantee__text__description mobile">
                        Jeśli kurs nie spełni twoich oczekiwań: &nbsp;
                        
                        <b onClick={
                            () => registerEvent(
                                props.authContext,
                                "returns inf",
                                "returns info",
                                "returns info"
                            )
                        }>
                            zwrócimy ci pieniądze <InfoIcon400/>
                        </b>.
                    </span>
                </div>
            </div>
        
        </>
    )
}