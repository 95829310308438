import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { combineBlocks } from "../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { MainContext } from "../MainContextProvider";
import { Block } from "../../../../../../shared/block";
import "./notesMain.scss";
import { get, patch } from "../../../../utilities/rest/apiClient";
import { NotesDto } from "../../../../../../shared/notesDto";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { NotesLinesFallback } from "../../additions/notes/NotesLinesFallback";
import { CourseContext } from "../../CourseContextProvider";

export const NotesMain = () => {
    const [currentNotes, setCurrentNotes] = useState([] as Block[]);
    const mainCtx = useContext(MainContext);
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const authContext = useContext(AuthContext);
    const ctx = useContext(CourseContext);

    const [downloadNotesOpen, setDownloadNotesOpen] = useState(false);


    useEffect(() => {

        mainCtx.setPath([mainCtx.mainDto.title, t("kurs.glowna.notatki.notatki"),
        mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].lessons[mainCtx.active.lessonIndex].title]);
        mainCtx.setActiveComponent('notes');

        
        (async ()=> {
            setIsLoading(true);
          try {
            
            const data = await get<NotesDto>(`reactRoutes/getNotes/${mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].lessons[mainCtx.active.lessonIndex].id}/${i18n.language}`);
            await patch<any, any>(`userChapters/incrementVisitedLessonNotes/${ctx.getLessonId()}`, {});
            setCurrentNotes(data.blocks);
            setIsLoading(false);
          } catch(error) {
            authContext.handleAuthErrors(error);
            //TODO obłsuga błędu
            console.log(error);
          }
        })()
    }, [mainCtx.active])

    return (
        <div className="data-panel__all-notes">
            <div className="data-panel__notes">
                <div className="data-panel__notes__headline">
                <h4> {`${t("kurs.glowna.notatki.przegladajNotatkiZNazwaLekcji")} ${mainCtx.path[2]}`}</h4>
                </div>
                <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--top"></div>
                <div className="data-panel__notes__container data-panel__all-notes__container">
                    <div className="data-panel__notes__container__data">
                        {(mainCtx.isLoading || isLoading) ? <NotesLinesFallback numberOfLines={15}/> : combineBlocks(currentNotes)}
                    </div>
                </div>
                <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--bottom"></div>
                <div className="data-panel__all-notes__downloads">



                <div 
                    className="data-panel__all-notes__downloads__headline"
                    onClick={() => setDownloadNotesOpen(!downloadNotesOpen)}    
                >
                    <span className={`material-symbols-outlined ${downloadNotesOpen ? 'active' : 'inactive'}`}>
                        arrow_back_ios
                    </span>
                    <span>{t("kurs.glowna.notatki.materialyDoPobrania")}</span>
                </div>

                {
                    downloadNotesOpen && 
                    <div className="data-panel__all-notes__downloads__data">
                        <div className="data-panel__all-notes__downloads__data__row">
                        <div className="data-panel__all-notes__downloads__data__row__description">
                            <MediaQuery minWidth={951}>
                                {`${t("kurs.glowna.notatki.downloadLessonNotes.long.p1")}: ${mainCtx.path[2]} ${t("kurs.glowna.notatki.downloadLessonNotes.long.p2")}`}
                            </MediaQuery>
                            <MediaQuery maxWidth={950}>
                                {t("kurs.glowna.notatki.downloadLessonNotes.short")}
                            </MediaQuery>
                        </div>
                        <div className="data-panel__all-notes__downloads__data__row__button">
                            <span>{t("kurs.glowna.notatki.pobierzPdf")}</span>
                            <span className="material-symbols-outlined">download</span>
                        </div>
                        </div>
                        <div className="data-panel__all-notes__downloads__data__spacer"></div>
                        <div className="data-panel__all-notes__downloads__data__row">
                        <div className="data-panel__all-notes__downloads__data__row__description">
                            <MediaQuery minWidth={951}>
                            {
                                `${t("kurs.glowna.notatki.downloadChapterNotes.long.p1")}: ${mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].title} ${t("kurs.glowna.notatki.downloadChapterNotes.long.p2")}`
                            }
                            </MediaQuery>
                            <MediaQuery maxWidth={950}>
                                {t("kurs.glowna.notatki.downloadChapterNotes.short")}
                            </MediaQuery>
                        </div>
                        <div className="data-panel__all-notes__downloads__data__row__button">
                            <span>{t("kurs.glowna.notatki.pobierzPdf")}</span>
                            <span className="material-symbols-outlined">download</span>
                        </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    );
}