import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssignmentDto } from "../../../../../shared/assignmentDto";
import { combineBlocks } from "../../../components/shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { VideoSolution } from "../../../components/course/additions/assignment/videoSolution/videoSolution";
import { PutPlaceholder } from "../../../components/course/additions/assignment/Assignment";

type PrevNext = "prev" | "next";

interface PreviewAssignmentProps {
    assignments: AssignmentDto[];
    activeAssignment: number;
    setActiveAssignment: Dispatch<SetStateAction<number>>; 
    explanationsAvailable: boolean;
}

export const PreviewAssignment = (props: PreviewAssignmentProps) => {
    const { t } = useTranslation();

    const { activeAssignment, setActiveAssignment, assignments } = props;


    const [showSolution, setShowSolution] = useState(false);
    const [videoSolutionOpen, setVideoSolutionOpen] = useState(false);

    const handleOpeningVideoSolution = () => {
        let html = document.querySelector('html');

        if(!videoSolutionOpen){    
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            document.body.classList.remove('body-not-scrollable');
            if(html !== null) html.classList.remove('html-not-scrollable');
        }
        setVideoSolutionOpen(!videoSolutionOpen);
    }

    const handleButtonClass = (type: PrevNext) => {
        const base = "data-panel__open-test__nav-buttons__button";

        let finalClassName = `${base} ${base}--${type}`;
        let inactive = false;

        if(type === "prev") inactive = (activeAssignment === 0);
        else if(type === "next") inactive = (activeAssignment === (assignments.length-1));

        finalClassName += inactive ? ` ${base}--inactive` : "";
        
        return finalClassName;
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    return (
        <>
            {
                videoSolutionOpen ? 
                <VideoSolution 
                    handleOpeningVideoSolution={handleOpeningVideoSolution} 
                    assignmentNumber={activeAssignment+1} 
                    assignmentsCount={assignments.length}
                    videoLink={assignments[activeAssignment].videoLink}
                    isFinalTest={undefined}
                /> : ""
            }

            <div className="data-panel__open-test">
                <div className="data-panel__open-test__question">
                    <div className="data-panel__open-test__question__question-number">
                        <h4 className="underlined">
                            {t("kurs.zadaniaOtwarte.zadanie")}: {activeAssignment+1} / {assignments.length}
                        </h4>
                    </div>

                    <div className="data-panel__open-test__question__question-data">

                        <div className="data-panel__open-test__question__question-data"> 
                            {combineBlocks(assignments[activeAssignment].text, "data-panel__notes__container__data").map(element => element)}
                        </div>

                    </div>

                </div>
                
                <div className="data-panel__open-test__anwser">
                        <div className="data-panel__open-test__anwser__headline">
                            <span>{t("kurs.zadaniaOtwarte.rozwiazanie")}:</span>
                        </div>

                    <div className="data-panel__open-test__anwser__data">

                        <div className={`data-panel__open-test__anwser__data__anwser ${showSolution && "data-panel__open-test__anwser__data__anwser--active"}`}>
                            {(showSolution === false) ?
                                            <PutPlaceholder/> :
                            combineBlocks(assignments[activeAssignment].solution, "data-panel__notes__container__data").map(element => element)}
                            
                        </div>

                        {((showSolution === false)) && 
                            <div className="data-panel__open-test__anwser__data__blur">
                                {props.explanationsAvailable && <div 
                                data-testid='show-explanation'
                                onClick={handleOpeningVideoSolution} className={`data-panel__open-test__anwser__data__blur__result-button data-panel__open-test__anwser__data__blur__result-button--filled ${videoSolutionOpen? "data-panel__open-test__anwser__data__blur__result-button--hidden" : ""}`}>
                                    <span>{t("kurs.zadaniaOtwarte.watchAssignmentVideo")}</span>
                                </div>}
                                <div 
                                    data-testid='show-solution'
                                    onClick={() => setShowSolution(true)} 
                                    className="data-panel__open-test__anwser__data__blur__result-button data-panel__open-test__anwser__data__blur__result-button--border-only"
                                >
                                    <span>{t("kurs.zadaniaOtwarte.zobaczRozwiazanie")}</span>
                                </div>
                            </div>
                        }
                    </div>

                </div>

                <div className="data-panel__open-test__buttons-spacer data-panel__open-test__buttons-spacer--pre"></div>

                <div className="data-panel__open-test__nav-buttons">
                    <div 
                        className={handleButtonClass('prev')}
                        onClick={ () => {
                            if(activeAssignment !== 0) {
                                setActiveAssignment(activeAssignment - 1);
                                setShowSolution(false);
                                setVideoSolutionOpen(false);
                                scrollToTop();
                            }
                        }}
                        data-testid='previous-button'
                        >
                            <span className="material-symbols-outlined">
                                arrow_back_ios
                            </span>     
                            <span>
                                {t("kurs.zadaniaOtwarte.poprzednie")}
                            </span>
                    </div>
                    <div 
                        className={handleButtonClass('next')}
                        onClick={ () => {
                            if(activeAssignment < assignments.length-1) {
                                setActiveAssignment(activeAssignment + 1);
                                setShowSolution(false);
                                setVideoSolutionOpen(false);
                                scrollToTop();
                            }
                        }}
                        data-testid='next-button'
                        >
                            <span>
                                {t("kurs.zadaniaOtwarte.nastepne")}
                            </span>
                            <span className="material-symbols-outlined">
                                arrow_forward_ios
                            </span>
                    </div>
                </div>

            </div>
        </>
    )
}