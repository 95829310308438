import "./paymentConfirmation.scss";


import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { DownLeftRayTeam } from "../MainPage/graphics/DownLeftRayTeam";
import { TopRightRayTeam } from "../MainPage/graphics/TopRightRayTeam";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { get } from "../../utilities/rest/apiClient";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";
import * as tiktok from "../../utilities/analytics/tiktok/tiktok";
import { Helmet } from "react-helmet";


const getSearchParameter = (name: string) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const param = params.get(name) as string;

    if(param){
        return param
    } return "search_param_undefined";
}

export const PaymentConfirmation = () => {
    usePageTracking("payment_confirmation");
    
    const navigate = useNavigate();

    useEffect(() => {

        tiktok.CompletePayment(
            getSearchParameter("courseID"),
            parseInt(getSearchParameter("price")),
            getSearchParameter("email")
        )
    }, [])

    useEffect(() => {
      const redirectTimeout = setTimeout(() => {

        navigate('/userPanel');
      }, 15000); 
  
      return () => clearTimeout(redirectTimeout);
    }, [navigate]);

  
    useEffect(() => {
        const url = new URL(window.location.href);

        const params = new URLSearchParams(url.search);

        // Get the value of a specific parameter by name
        const courseID = params.get('courseID') as string;
        if(!courseID) {
            // console.log("course ID is null");
            return;
        }

        const purchaseType = params.get('purchaseType') as string;
        if(!purchaseType) {
            // console.log("purchase type is null");
            return;
        }

        let intervalId:  NodeJS.Timer;

        if(purchaseType === "course") {
            intervalId = setInterval(() => {
                (async () => {
                    try{    
                        get<any>(`reactRoutes/getUserHasCourse/${courseID}`)
                        .then(() => {
                            navigate(`/userPanel`);
                        }).catch(() => {

                        });
                    }catch{

                    }
                })();
            }, 1000);
        } else if(purchaseType === "explanation") {
            intervalId = setInterval(() => {
                (async () => {
                    try{    
                        get<any>(`reactRoutes/getUserHasExplanations/${courseID}`)
                        .then(() => {
                            navigate(`/userPanel`);
                        }).catch(() => {

                        });
                    }catch{

                    }
                })();
            }, 1000);
        }
        

        return () => clearInterval(intervalId);
    }, []);


    // useEffect(() => {
    //     // Create the script element
    //     const script = document.createElement('script');
    //     script.innerHTML = `
    //       gtag('event', 'conversion', {
    //         'send_to': 'AW-16740954580/ha56CIDO0t0ZENTj2q4-'
    //       });
    //     `;
        
    //     // Add the script to the head section
    //     document.head.appendChild(script);
    
    //     // Clean up the script when the component unmounts
    //     return () => {
    //       document.head.removeChild(script);
    //     };
    //   }, []);
    
    return (
        <div className="payment-confirmation">
            <LandingNavbar/>
            {/* <Helmet>
                <script>
                {`
                    gtag('event', 'conversion', {
                    'send_to': 'AW-16740954580/ha56CIDO0t0ZENTj2q4-'
                    });
                `}
                </script>
            </Helmet> */}
                    
            <div className="payment-confirmation__main">
                <DownLeftRayTeam/>
                <TopRightRayTeam/>

                <div className="payment-confirmation__main__panel">

                    <div className="payment-confirmation__main__panel__headline_1">
                        <span> Dziękujemy za dokonanie zakupu.</span>
                    </div>

                    <div className="payment-confirmation__main__panel__confirmation">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>

                    <div className="payment-confirmation__main__panel__text">
                        <span> Zaraz przekierujemy cię do twojego panelu użytkownika.</span>
                    </div>

                    <div className="payment-confirmation__main__panel__footer">
                        <span>
                            Potwiedzenie płatności zostanie wysłane na twojego maila.
                        </span>
                    </div>
                </div>
            </div>

            {/* <LandingFooter/> */}
        </div>
    )
}

