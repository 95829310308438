
import { DownLeftRayTeam } from "../graphics/DownLeftRayTeam";
import { TopRightRayTeam } from "../graphics/TopRightRayTeam";
import { LeftIcons } from "./TabIcons/LeftIcons";
import { RightIcons } from "./TabIcons/RightIcons";
import { Shield } from "./TabIcons/Shield";

import "./videoPreview.scss";
import { useEffect, useRef } from "react";
const playerjs =  require("player.js");

export const VideoPreview = () => {

    return (
        <div className="main-page__safari-frame-wrapper">
            <div className="main-page__safari-frame-headline">
                <DownLeftRayTeam/>
                <TopRightRayTeam/>

                <div className="main-page__safari-frame-headline__headline">
                    <span className="desktop">Sprawdź jak może wyglądać nauka <b>NaSesje</b>.</span>

                    <span className="mobile">Nauka z <b>NaSesje</b>:</span>
                </div>
                <div className="main-page__safari-frame-headline__text">
                    <span className="desktop">
                        Obejrzyj nagranie prezentujące platformę i przekonaj się, że nauka z nami może być znacznie przyjemniejsza i efektywniejsza niż szukanie niezrozumiałych rozwiązań w internecie.
                    </span>

                    <span className="mobile">
                        jest przyjemniejsza i efektywniejsza niż szukanie rozwiązań w internecie.
                    </span>
                </div>
            </div>
            <div className="main-page__safari-frame">
                <div className="main-page__safari-frame__address-bar">
                    
                    <div className="main-page__safari-frame__left-icons-group">

                        <div className="main-page__safari-frame__left-icons-group__open-close-maximize">
                            <div className="main-page__safari-frame__status-circle red-circle"></div>
                            <div className="main-page__safari-frame__status-circle green-circle"></div>
                            <div className="main-page__safari-frame__status-circle orange-circle"></div>
                        </div>
                        
                        <LeftIcons/>

                    </div>


                    <Shield/>

                    
                    <div className="main-page__safari-frame__center-icons-group">
                        {/* <span className="material-symbols-outlined shield">
                            shield
                        </span> */}
                        <div className="main-page__safari-frame__address-text">
                            https://nasesje.com

                            <span className="material-symbols-outlined refresh-icon">
                                refresh
                            </span>
                        </div>
                    </div>

                    <div className="main-page__safari-frame__right-icons-group">
                        <RightIcons/>
                    </div>
                    

                </div>


                 <div className="iframe-wrapper">
                    <iframe 
                        title="landing-material"
                        src="https://iframe.mediadelivery.net/embed/227191/d4b58fb1-a15f-494c-9c2f-032100dda679?autoplay=false&loop=false&muted=false&preload=true&responsive=true" 
                        loading="lazy" 
                        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" 
                        allowFullScreen={true}
                        scrolling="no"
                        frameBorder={0}
                    ></iframe>
                </div> 


            </div>


        </div>
    )
}