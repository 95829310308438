

export const LogoNoText = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      viewBox="0 0 100.5 150.7"
    >
      <style>{".st0{fill:#1a1a1a}"}</style>
      <title>{"Asset 1"}</title>
      <g id="Layer_2_1_">
        <g id="Layer_1-2">
          <path
            d="M59.1 53.9v59.8"
            style={{
              fill: "none",
            }}
          />
          <path
            d="M59.1 53.9v59.8"
            style={{
              fill: "none",
              stroke: "#1a1a1a",
              strokeWidth: 3,
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M106.9 27.6 59.1 55.1 11.4 27.6 59.1 0zM61 58.5l47.8-17.3V31zM108.8 41.2v44.9L61 113.7l57.2-20.8v-55l-9.4 3.3zM57.2 58.5 9.5 31v10.2l47.7 17.3zM0 92.8l57.2 20.8L9.5 86.1V41.2L0 37.8v55z"
            className="st0"
          />
        </g>
      </g>
    </svg>
  )
  
  