import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import Latex from "react-latex";

interface QuizAnswerProps {
    label: string;
    src: string;
    id: number;
    currentIndex: number;
    correctQuestionId: number;
    handleClickAnswer: (userAnswerId: number, correctQuestionId: number) => Promise<void> | void;

    activeMobileIndex: number;
    setActiveMobileIndex: Dispatch<SetStateAction<number>>;

    srcIsImage: boolean;
}



export const QuizAnswer = (props: QuizAnswerProps) => {

    const processLeqSigns = (text: string) => {
        return text.replace(/&lt;/g, "<");
    }

    return (
        <div className={`data-panel__quiz__anwsers__anwser${(props.currentIndex !== props.activeMobileIndex) ? " data-panel__quiz__anwsers__anwser--hide-mobile" : ""}`}
            onClick={() => {
                props.setActiveMobileIndex(0);
                props.handleClickAnswer(props.id, props.correctQuestionId);
            } }
            data-testid={`quiz-option-${props.label}`}
        >
            <div className="data-panel__quiz__anwsers__anwser__index">{props.label}
                
            </div>
            <div className="data-panel__quiz__anwsers__anwser__anwsers-wrapper">
                <div className="data-panel__quiz__anwsers__anwser__anwsers-wrapper__anwser">
                {
                    props.srcIsImage ? <img alt="" src={props.src}/> 
                    : 
                    <>                    
                        <img alt="" src={"https://na-sesje-public.s3.eu-central-1.amazonaws.com/empty_answer.svg"}/>
                        <div className="text-anwser">
                            <span className="text-style"> 
                                <Latex>{processLeqSigns(props.src)}</Latex>
                            </span>
                        </div>
                    </>
                }
                </div>
                
            </div>
           
        </div>
    );
}