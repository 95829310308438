

import { useEffect, useRef, useState } from "react";
import "./laptopSection.scss";
import { ArrowForwardIos400 } from "../../Icons/ArrowForwardIos400";

export const LaptopSection = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const features = [
        { description: "Animowane lekcje z przedmiotów inżynierskich.", link: "laptop_2_2", feature: "filmy" },
        { description: "Quizy sprawdzające wiedzę do każdej lekcji.", link: "laptop_3", feature: "quizy" },
        { description: "Zadania otwarte rozszerzające wiedzę z lekcji.", link: "laptop_4", feature: "zadania otwarte" },
        { description: "Notatki przygotowane do każdej lekcji.", link: "laptop_5", feature: "notatki" },
    ];

    const handleRightButtonClick = () => {
        if(activeIndex < (features.length-1))
            setActiveIndex(activeIndex +1);
    }

    const handleLeftButtonClick = () => {
        if(activeIndex > 0) setActiveIndex(activeIndex - 1);
    }

    return (
        <div className="main-page__mobile-intro">
            <span className="main-page__mobile-intro__headline-1">
                Kursy na <b>studia</b> 🎓
            </span>
            <span className="main-page__mobile-intro__headline-2">
                {
                    features.map((el, index) => {
                        let element = null

                        if(index === activeIndex) element = <b>{el.feature}</b>
                        else element = <>{el.feature}</>

                        if(index !== (features.length-1))
                            element = <>{element}, </>

                        return element;
                    })
                }
            </span>
            <div className="main-page__mobile-intro__img-wrapper">
                <img className="laptop" alt="" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/laptops/${features[activeIndex].link}.webp`}></img>
                <div className="main-page__mobile-intro__buttons">
                    <button
                        className={`main-page__mobile-intro__buttons__button-left ${(activeIndex === 0) && 'inactive'}`}
                        onClick={handleLeftButtonClick}
                    >
                        <ArrowForwardIos400/>
                    </button>
                    <button 
                        className={`main-page__mobile-intro__buttons__button-right ${(activeIndex === (features.length - 1) && 'inactive')}`}
                        onClick={handleRightButtonClick}
                    >
                        <ArrowForwardIos400/>
                    </button>
                </div>
            </div>
            <div className="main-page__mobile-intro__subtitle">
                <span>
                    {features[activeIndex].description}
                </span>
            </div>
            {/* <div className="main-page__mobile-intro__scroll-status">
                {
                    features.map((el, index) => 
                        <div
                            key={index}
                            className={`main-page__our-offer__mobile-scroll-status__dot${(activeIndex === index)? ' main-page__our-offer__mobile-scroll-status__dot--active' : ''}`}
                        />
                    )
                }
            </div> */}
        </div>
    )
}