import { useContext, useEffect, useState } from "react";
import { CourseMainNavigationChapter } from "./courseMainNavigationChapter/MainNavigationChapter"
import { CourseMainNavigationFallback } from "./courseMainNavigationFallback/courseMainNavigationFallback";
import { MainContext } from "../../MainContextProvider";
import { MainNavigationFallbackWithRegardsToUrl } from "../../../../shared/components/fallbacks/fallbackUtils";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../../../global/globalDataContext/GlobalDataContextProvider";
import { useTranslation } from "react-i18next";
import { LastVisitedDto } from "shared/lastVisitedDto";
import { get } from "../../../../../utilities/rest/apiClient";


interface RepetitionBlockProps {
    lastVisitedChapter: string,
    section: string,
    icon: string,
    heading: string
}


export const RepetitionBlock = (props: RepetitionBlockProps) => {

    const navigate = useNavigate();

    const {lastVisitedChapter, section, icon, heading} = props;

    const handleClick = () => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth' 
        });

        setTimeout(() => {
            navigate(`${lastVisitedChapter}/${section}`);
        }, 300);
    }

    return (

    <div 
        className="chapter__row__box"  
        onClick={handleClick}
    >
        <span className="material-symbols-outlined">
            {icon}
        </span>
        <span>
            {heading}
        </span>
    </div>
    )
}


export const CourseMainNavigation = () => {
    const mainCtx = useContext(MainContext);
    const navigate = useNavigate();
    const globalCtx = useContext(GlobalDataContext);   
    const { t } = useTranslation(); 

    const { courseId } = useParams();

    const [lastVisitedChapter, setLastVisitedChapter] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const sections = [
        { section: "quiz", icon: "extension", heading: t("kurs.glowna.quizes")  },
        { section: "assignment", icon: "neurology", heading: t("kurs.glowna.tasks") },
        { section: "notes", icon: "description", heading: t("kurs.glowna.notes") }
    ];

    useEffect(() => {
        setIsLoading(true);
        globalCtx.setIsMainTest(false);

        (async ()=> {
          try {
            console.log(courseId);
            const lastVisited = await get<LastVisitedDto>(`reactRoutes/getLastVisited/${courseId}`);
            setLastVisitedChapter(lastVisited.lastVisitedChapter);
            setIsLoading(false);
          } catch(error) {
            //TODO obłsuga błędu
            console.log(error);
          }
        })()
    }, [])

    const handleFinalTestClick = () => {

        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });

        setTimeout(() => {
            navigate("test/preview");            
        }, 500);

    }

    if(globalCtx.isMainTest){
        return <></>
    }
    else if((mainCtx.chapterData.length === 0) || isLoading) {
        return <MainNavigationFallbackWithRegardsToUrl/>
    } else {
        return (
            <div id="navigation-panel">
                <div className="chapter chapter--main">
                    <div className="chapter__name">Działy kursu</div>
                    <div className="chapter__data-space">
                        {mainCtx.chapterData.map((element, index) => <CourseMainNavigationChapter key={index} id={element.id} index={index+1} name={element.name} finishedEpisodes={element.finishedEpisodes} maxEpisodes={element.maxEpisodes} fullDurationHours={element.fullDurationHours} fullDurationMinures={element.fullDurationMinures}/>)}
                    </div>

                    
                </div>

                <div className="chapter chapter--main chapter--additional-space">
                    <div className="chapter__name">Powtórka po kursie</div>
                    <div className="chapter__row">
                        
                        {
                            sections.map((el, index) => 
                                <RepetitionBlock 
                                    key={index} 
                                    {...el}                               
                                    lastVisitedChapter={lastVisitedChapter} 
                                />   
                            )
                        }

                    </div>

                    <div className="chapter__bottom-spacer"></div>


                    <div onClick={handleFinalTestClick} className="chapter__data-space__data chapter__data-space__data--highlited chapter__data-space__data--iconless chapter__data-space__data--clickable">
                        <div className="text">{t("kurs.testKoncowy.rozwiazTestKoncowy")}</div>
                        <div className="stamp">{`${mainCtx.finishedFinalTest ? 1 : 0}/${1}`}</div>
                    </div>
    
                </div>
                                
            </div>
        );
    }
}