import React, { Dispatch, SetStateAction, useEffect, useState, forwardRef } from "react";

interface CarouselElementProps {
    index: number,
    activeIndex: number,
    setActiveIndex: Dispatch<SetStateAction<number>>,
    setPositionStyle: Dispatch<SetStateAction<{ left: string; transition: string}>>,
    text: string,
    scrollLeft: boolean,
    setDeactivatedPrevIndex: Dispatch<SetStateAction<boolean>>,
    deactivatedPrevIndex: boolean;
    panelCount: number;

    mutexTimer: boolean;
    setMutexTimer: Dispatch<SetStateAction<boolean>>;
}

export const CarouselElement = forwardRef<HTMLDivElement, CarouselElementProps>((props, ref) => {
    const [lazyLoad, setLazyLoad] = useState(false);

    useEffect(() => {
        if (lazyLoad) setLazyLoad(false);

        if (props.activeIndex === props.index) {
            setTimeout(() => {
                setLazyLoad(true);
            }, 50);
        }

    }, [props.activeIndex]);

    const handlePanelClass = () => {
        const base = "main-page__carousel-section__carousel__row__panel";
        const active = (props.activeIndex === props.index);
        
        let finalClass = `${base}${(lazyLoad && active) ? ` ${base}--positioned` : ""}`;

        if (props.deactivatedPrevIndex && active) finalClass = `${base} ${base}--deactivated`;

        return finalClass;
    };

    const transformCarousel = (currentIndex: number) => {
        const sign = props.scrollLeft ? '0' : '2';
        const parameter = `calc( var(--base-step) - ${sign} * var(--panel-width)  )`;

        props.setPositionStyle(
            { left: parameter, transition: 'left 0.6s ease' }
        );
    };

    const handleClick = () => {
        if(!props.mutexTimer) {

            props.setDeactivatedPrevIndex(true);

            props.setMutexTimer(true);

            transformCarousel(props.index);

            let nextIndex = props.index;

            if (props.activeIndex === props.index) {
                nextIndex = props.index < props.panelCount - 1 ? props.activeIndex + 1 : 0;
            }

            setTimeout(() => {
                props.setDeactivatedPrevIndex(false);
                props.setPositionStyle({
                    left: 'calc( var(--base-step) - 1 * var(--panel-width)  )',
                    transition: 'left 0s ease'
                });
                props.setActiveIndex(nextIndex);
            }, 700);

            setTimeout(() => {
                props.setMutexTimer(false);
            }, 1300);

        }
    };

    return (
        <div
            className={handlePanelClass()}
            onClick={handleClick}
            ref={ref}
        >
            <div className="main-page__carousel-section__carousel__row__panel__img-wrapper main-page__carousel-section__carousel__row__panel__img-wrapper--desktop">            
                    <div className={ `main-page__carousel-section__carousel__row__panel__img-hover-wrapper main-page__carousel-section__carousel__row__panel__img-hover-wrapper--${(props.activeIndex !== props.index) ? 'active' : ''}` }>
                        <img
                            loading="lazy"
                            src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/platform-panels-preview/panel-0${props.index}.png`}
                            alt="feature"
                        />
                    </div>
            </div>

            <div className="main-page__carousel-section__carousel__row__panel__img-wrapper main-page__carousel-section__carousel__row__panel__img-wrapper--mobile">
                <img
                    src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/mobile-platform-panels-preview/panel-0${props.index}.png`}
                    alt="feature"
                    loading="lazy"
                />
            </div>

            <div className="main-page__carousel-section__carousel__row__panel__description">
                <span>{props.text}</span>
            </div>
        </div>
    );
});
