import { useNavigate } from "react-router-dom";

import "./goToShopPanel.scss";
import "../../../SellingCoursePanel/sellingCoursePanel.scss";
import "../../../../components/userPanel/userPanelPages/myCourses/coursePanel/CoursePanel.scss";


import { useTranslation } from "react-i18next";
import { ArrowGraphic } from "../../graphics/ArrowGraphic";
import { PlayCircle } from "../../graphics/PlayCircle";
import { ClockCircle } from "../../graphics/ClockCircle";

interface GoToShopPanelProps { }

export const GoToShopPanel = (props: GoToShopPanelProps) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <div className="course-panel selling-course-panel go-to-shop-panel">
                <div className="course-panel__main-name selling-course-panel__main-name">
                    <div className="course-panel__main-name__img selling-course-panel__main-name__img">
                        <img
                            src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/course-icons/empty-course-wide.png"
                            alt="kurs"
                        />
                    </div>
                    <div className="course-panel__main-name__text selling-course-panel__main-name__text">
                        <span className="course-panel__main-name__text__element course-panel__main-name__text__element--first course-panel__main-name__text__element--24">
                            Pozostałe
                        </span>
                        <span className="course-panel__main-name__text__element course-panel__main-name__text__element--second course-panel__main-name__text__element--20">
                            dostępne kursy
                        </span>
                    </div>
                </div>
                <div className="course-panel__name">
                    <span>Znajdź coś dla siebie</span>
                </div>
                <div className="course-panel__description selling-course-panel__description go-to-shop-panel__description">
                    <span>
                        Kliknij w poniższy przycisk, aby zobaczyć wszystkie kursy jakie dostępne są na platformie NaSesje.com.
                    </span>
                </div>

                <div className="go-to-shop-panel__data">
                    <div className="go-to-shop-panel__data__name">
                        <span>Wybieraj spośród: </span>
                    </div>

                    <div className="go-to-shop-panel__data__row">
                        <PlayCircle/>
                        <span>+ {150} dostępnych lekcji </span>
                    </div>
                    <div className="go-to-shop-panel__data__row">
                        <ClockCircle/>
                        <span>+ {75} godzin nagrań</span>
                    </div>
                    <div className="go-to-shop-panel__data__row">
                        <span className="material-symbols-outlined">
                            add_circle
                        </span>
                        <span>+ {1000} pozostałych materiałów</span>
                    </div>
                </div>




                <div className="course-panel__content-wrapper go-to-shop-panel__content-wrapper">
                    <div className="course-panel__content-wrapper__button go-to-shop-panel__content-wrapper__button"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/shop')}
                        }>
                            <span>
                                Poznaj pełną ofertę
                            </span>
                            <ArrowGraphic/>
                    </div>
                </div>
            </div>
            <div className="artificial-spacer">&nbsp;</div>
        </>
    );
}