


import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { MailIcon } from "./icons/MailIcon";
import { QuestionIcon } from "./icons/QuestionIcon";

import { Phone } from "./icons/bodyIcons/Phone";
import { Email } from "./icons/bodyIcons/Email";
import { Chat } from "./icons/bodyIcons/Chat";
import { Play } from "./icons/bodyIcons/Play";
import { LandingFB } from "./icons/bodyIcons/LandingFB";
import { Instagram } from "./icons/bodyIcons/Instagram";
import { TikTokFooter } from "./icons/bodyIcons/TikTokFooter";

import "./landingFooter.scss";

import { Dispatch, SetStateAction, useState } from "react";
import { ElernaGroup } from "./ElernaGroup/ElernaGroup";
import { ArrowForwardIos300 } from "../Icons/ArrowForwardIos300";
import { ArrowForwardIos400 } from "../Icons/ArrowForwardIos400";



interface Column {
    title: string,
    elements: Array<{
        text: string;
        path: string;
        icon?: JSX.Element | undefined;
    }>;
}

interface ColumnElementProps {
    column: Column,
    active: boolean,
    myIndex: number,
    setActiveIndex: Dispatch<SetStateAction<number>>
}

const ColumnElement = (props: ColumnElementProps) => {

    const navigate = useNavigate();

    const { column, active, myIndex } = props

    const handleClick = (path: string) => {

        if(path === "") return;

        if(path.startsWith("http")) {
            window.location.href = path;
        } else {
            window.scrollTo(0, 0);
            navigate(path);
        }
    }

    return (
        <div>
            <div 
                className={`landing-footer__content__footer-headline${active ? '' : ' landing-footer__content__footer-headline--inactive'}`}
                onClick={() => { props.setActiveIndex(myIndex)}}
            >
                <h4>{column.title}</h4>
    
                {
                    active ?
                    <span className="expand_less">
                        <ArrowForwardIos400/>
                    </span> :
                    <span className="expand_more">
                        <ArrowForwardIos400/>
                    </span>
                }

            </div>
            <div className={`landing-footer__content__footer-data${active ? '' : ' landing-footer__content__footer-data--inactive'}`}>
                {
                    column.elements.map((el, index) => {
                        return (
                            <p
                                className={`${ (el.path === "") ? "non-clickable" : ""}`} 
                                onClick={() => handleClick(el.path)} key={index}
                            >
                                { (el.icon !== undefined) ? el.icon : "" }
                                <span>{el.text}</span>
                            </p>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const LandingFooter = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activeIndex, setActiveIndex] = useState(0);
    
    const content: Column[] = [
        {
            title: t("footer.bottomHeadlines.moreInformations"),
            elements: [
                {text: t("footer.bottomLinks.aboutUs"), path: "/aboutUs" },
                {text: t("footer.bottomLinks.partnership"), path: "" },
                {text: t("footer.bottomLinks.termsOfService"), path: "/regulamin" },
                {text: t("footer.bottomLinks.privacyPolicy"), path: "/politykaPrywatnosci" },
                {text: t("footer.bottomLinks.informativeDuties"), path: "/obowiazekInformacyjny" }
            ]
        },
        {
            title: t("footer.bottomHeadlines.helpfullLinks"),
            elements: [
                {text: t("footer.bottomLinks.myAccount"), path: "/userPanel" },
                {text: t("footer.bottomLinks.courseShop"), path: "/shop" },
                {text: t("footer.bottomLinks.FAQSection"), path: "/FAQLanding" },
                {text: t("footer.bottomLinks.forum"), path: "#" }
            ]
        },
        {
            title: t("footer.bottomHeadlines.contact"),
            elements: [
                {text: "731 191 205", path: "", icon: <Phone/>},
                {text: t("supportEmail"), path: "", icon:  <Email/> },
                {text: "Messenger", path: "https://m.me/61558293877112", icon:  <Chat/> },
            ]
        },
        {
            title: "Social media",
            elements: [
                {text: "Youtube", path: "https://youtube.com/@NaSesje?si=INgVSj4vD5JyW6Nh", icon:  <Play/> },
                {text: "Facebook", path: "https://www.facebook.com/profile.php?id=61558293877112", icon:  <LandingFB/>},
                {text: "Instagram", path: "https://www.instagram.com/nasesje?igsh=OGRnd2MwY25wOGU4", icon:  <Instagram/> },
                {text: "TikTok", path: "https://www.tiktok.com/@nasesje.com", icon: <TikTokFooter/>}
            ]
        },
    ];

    const sendEmail = () => {
        window.location.href = "mailto: support@naSesje.com?subject=Zapytanie";
    }

    const goToFAQ = () => {
        navigate("/FAQLanding");
    }

    return (
        <div className="landing-footer">
            {/* <div className="landing-footer__logos">
                <ElernaGroup/>
            </div> */}
            <div className="landing-footer__heading">
                <div className="landing-footer__heading__text">
                    <span className="landing-footer__heading__text__headline">
                        {t("footer.anyQuestionsAsk")}
                    </span>
                    <span className="landing-footer__heading__text__main">
                        {t("footer.anyQuestionsAnswer")}
                    </span>
                </div>
                <div className="landing-footer__heading__buttons">
                    <div 
                        onClick={() => sendEmail()} 
                        className="landing-footer__heading__buttons__button landing-footer__heading__buttons__button--left yellow-button"
                    >   
                        {t("footer.sendEmail")}
                        <MailIcon/>
                    </div>
                    <div 
                        className="landing-footer__heading__buttons__button landing-footer__heading__buttons__button--right" 
                        onClick={() => goToFAQ()}
                    >
                        {t("footer.FAQSection")}
                        <QuestionIcon/>
                    </div>
                </div>
            </div>

            <div className="landing-footer__content">
                {
                    content.map((el, index) => 
                        <ColumnElement 
                            active={ (index === activeIndex) } 
                            column={el} 
                            setActiveIndex={setActiveIndex} 
                            key={index}
                            myIndex={index}
                        />
                    )
                }
            </div>

            <footer>
                <span>{t("platformName")} 2024 ©. {t("footer.copyrightDisclaimer.allRightsReserved")}.</span>
            </footer>    
        </div>
    )

}