

import { useEffect, useRef, useState } from "react";
import "./offerBoxes.scss";
import { useTranslation } from "react-i18next";

interface FeatureElementProps {
    index: number,
    heading: string,
    text: string,
    last: boolean,
}

const FeatureElement = (props: FeatureElementProps) => {
    return (
        <div className={`main-page__our-offer__boxes__box ${props.last ? "main-page__our-offer__boxes__box--last" : ""}`}>
            <div className="main-page__our-offer__boxes__box__wrapper">
                <div className="main-page__our-offer__boxes__box__main">

                    <img src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/features-preview/feature0${props.index+1}.png`} alt="feature"/>
                </div>

                <div className="main-page__our-offer__boxes__box__main__headline">
                        <span>{props.heading}</span>
                </div>
            </div>
            <div className="main-page__our-offer__boxes__box__main__text">
                    <span>{props.text}</span>
            </div>
        </div>
    )
}

interface StateMonitor {
    dummy: number;
    increasing: boolean;
  }

export const OfferBoxes = () => {
    const { t } = useTranslation();
    
    const elementRef = useRef<HTMLDivElement>(null);
    
    const [activeBoxIndex, setActiveBoxIndex] = useState(-1);
    const [increasing, setIncreasing] = useState({increasing: true, dummy: 0} as StateMonitor);
    const [aweker, setAweker] = useState(false);
    const [positionStyle, setPositionStyle] = useState({ left: '-0vw'});
    const [scrolledToHeight, setScrolledToHeight] = useState(false);

    const timerInterval = 5000; // 10s

    useEffect(() => {
        function handleScroll() {

          if (elementRef.current) {
            const position = elementRef.current.offsetTop + 315;
            const scrollTop = window.scrollY;
            
            if (scrollTop >= position) {
              setScrolledToHeight(true);
              window.removeEventListener('scroll', handleScroll);    
            }
          }  
        }
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if(scrolledToHeight) {

            movePanel();
            const timer = setInterval(movePanel, timerInterval);
        
            return () => {
                clearInterval(timer);
            };
        }
    }, [scrolledToHeight]);

    const movePanel = () => {
        setAweker(prevAweker => !prevAweker); 
    };

    useEffect(() => {
        const topIndexLimitReach = (activeBoxIndex === (features.length - 1));
        const bottomIndexLimitReached = (activeBoxIndex === 0);

        if(topIndexLimitReach) {
            setIncreasing({increasing: false, dummy: 1});
        } else if(bottomIndexLimitReached){

            if(!increasing.increasing) {
                setIncreasing({increasing: true, dummy: 1});
            } 
        } else {
            setIncreasing({increasing: increasing.increasing, dummy: increasing.dummy + 1});
        }      
    }, [aweker])

    useEffect(() => {
        const increasingLocal = increasing.increasing;

        const newIndex = activeBoxIndex + ( increasingLocal ? 1 : -1);

        setActiveBoxIndex(newIndex);

        setPositionStyle({
            left: `-${newIndex * 100}vw`
        });

    }, [increasing]);

    const features = [
        [t("landing.features.highQuality") , t("landing.features.headings.quality")],
        [t("landing.features.lessonsDivided"), t("landing.features.headings.value")],
        [t("landing.features.simpleExplanation"), t("landing.features.headings.simplicity")],
        [t("landing.features.wellStructuredKnowledge"), t("landing.features.headings.organization")],
        [t("landing.features.youCanLearnFromEveryPlace"), t("landing.features.headings.avaiability")]
    ];

    return (

        <div className="main-page__our-offer">
            <div className="main-page__our-offer__headline">
                <span className="main-page__our-offer__headline__text main-page__our-offer__headline__text--normal">Jakie są </span>
                <span className="main-page__our-offer__headline__text main-page__our-offer__headline__text--highlighted">nasze wartości</span>
                <span className="main-page__our-offer__headline__text main-page__our-offer__headline__text--normal"> ?</span>
            </div>
            
            <div className="main-page__our-offer__boxes" style={positionStyle}>
                {
                    features.map((el, index) => 
                        <FeatureElement 
                            last={ (index === features.length-1) }
                            text={el[0]}
                            heading={el[1]} 
                            index={index} 
                            key={index}
                        />
                    )
                }
            </div>
            <div className="main-page__our-offer__mobile-scroll-status" ref={elementRef}>
                {
                    features.map((el, index) => 
                        <div
                            key={index}
                            className={`main-page__our-offer__mobile-scroll-status__dot${(activeBoxIndex === index)? ' main-page__our-offer__mobile-scroll-status__dot--active' : ''}`}
                        />
                    )
                }
            </div>
        </div>
    )
}