import { Block } from "../../../../../shared/block";
import { useTranslation } from "react-i18next";
import { Guarantee } from "../../MainPage/Guarantee/Guarantee";
import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../components/auth/authContext/AuthContextProvider";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Authors, AuthorsProps } from "./Authors/Authors";

interface DisplayedCourseDescriptionProps {
    description: Block[];
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[]
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    setAboutAuthorsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;

    courseName: string;
}

interface TableOfContentsProps {
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[]
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
}


const AuthorsData: AuthorsProps[] = [
    {
        imgSrc: "alex_img",
        // imgSrc: "alex_img_desktop",
        name: "Aleksander Kotarski",
        description: "Ukończył z wyróżnieniem Informatykę stosowaną na Uniwersytecie Jagielońskim. Korepetytor matematyki i informatyki z wieloletnim doświadczeniem.",
        bulletPoints: [
            "Współzałożyciel serwisu Nasesje.com",
            "Autor kursów i artykułów specjalistycznych",
            "Autor książki \"Poręczny C++\""
        ],
        myCourses: [
            "Macierze i Wektory",
            "Granice ciągów i funkcji",
            "Teoria języków formalnych",
            "Liczby Binarne i Algebra Boole'a"
        ]
    },
    {
        imgSrc: "piotr_img",
        name: "Piotr Książek",
        description: "Absolwent Informatyki Stosowanej na Uniwersytecie Jagiellońskim, programista z czteroletnim doświadczeniem komercyjnym w językach C#, C++ oraz TypeScript. Pracował nad tworzeniem gier 3D oraz rozwojem oprogramowania Autodesk Civil3D, gdzie mógł wykorzystać w praktyce wiedzę z zakresu informatyki, algebry liniowej oraz analizy matematycznej.",
        bulletPoints: [
            "Współzałożyciel serwisu Nasesje.com",
            "Autor kursów i artykułów specjalistycznych",
        ],
        myCourses: [
            "Macierze i Wektory",
            "Liczby zespolone",
            "Teoria języków formalnych",
            "Liczby Binarne i Algebra Boole'a"
        ]
    }
]


const TableOfContents = (props: TableOfContentsProps) => {
    const tableOfContentsRef = useRef<HTMLDivElement>(null);
    const { courseId } = useParams();


    

    useEffect(() => {
        props.setTableOfContentsRef(tableOfContentsRef.current);
    }, [tableOfContentsRef])

    const isActive = (chapterIndex: number, lessonIndex: number) => {
        if(chapterIndex === props.publiclyAvailableChapter) {
            if(props.publiclyAvailableLessons.includes(lessonIndex)) {
                return true;
            }
        } return false;
    }

    const navigate = useNavigate();

    const handleActiveLessonClick = () => {
        navigate(`/lessonPreview/${courseId}`)
    }

    return (
        // <div className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents">
        <div ref={tableOfContentsRef}>
            {props.courseContent.map((chapter, chapterIndex) => {
                return (
                    <div key={chapterIndex} className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents">
                        <h2>👉 {(chapterIndex + 1) + " " + chapter.chapterName}</h2>
                        <ul>
                            {chapter.lessons.map((lesson, index) => {
                                if(isActive(chapterIndex, index)) {
                                    if(props.fromLanding) return  <li className={"active"} key={index}>{lesson } ( tu jesteś )</li>
                                    
                                    return (                                        
                                        <li onClick={handleActiveLessonClick} className={"active"} key={index}>{lesson } (darmowa lekcja - kliknij aby przejść)</li>
                                    )
                                }
                                return  <li className="inactive" key={index}>{lesson}</li>
                            })}
                        </ul>
                    </div>
                )
            })}
        </div>
    )
}

interface BulletProps {
    emoji: string;
    children: JSX.Element;
}

const Bullet = (props: BulletProps) => {
    return (
        <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container">
            <span className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji">
                {props.emoji}
            </span>
            <span>{props.children}</span>
        </div>
    )
}

export const DisplayedCourseDescription = (props: DisplayedCourseDescriptionProps) => {
    const { t } = useTranslation();
    
    const authContext = useContext(AuthContext);

    const bulletPoints = [
        t("kurs.preview.previewPage.bulletPoints.first"),
        t("kurs.preview.previewPage.bulletPoints.second"),
        t("kurs.preview.previewPage.bulletPoints.third"),
        t("kurs.preview.previewPage.bulletPoints.fourth")
    ];

    const aboutAuthorsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        props.setAboutAuthorsRef(aboutAuthorsRef.current);
    }, [aboutAuthorsRef])


    return (
        <div className={`course-preview__main-section__left-panel__text-section ${(props.fromLanding) && "course-preview__main-section__left-panel__text-section--from-landing"}`}>

            <div className="course-preview__main-section__left-panel__text-section__text">
                {/* {combineBlocks(props.description, null)} */}
            </div>

            <div className="course-preview__main-section__left-panel__text-section__learning-method">
                
                {
                    props.fromLanding &&              
                    <div className="course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator--top">

                    </div> 
                }
                <h1>
                    Dokładne informacje
                </h1>

                <div className="course-preview__main-section__left-panel__text-section__learning-method__text">
                    <span>  
                        Każda lekcja to solidna dawka <b>wiedzy praktycznej</b>, 
                        której potrzebujesz do <b className="purple">zdania kolokwiów i sesji.</b> Prowadzimy za rękę przez najważniejsze zagadnienia, w przyjemnej atmosferze i w tempie, które wybierasz Ty.
                    </span>
                    <br/><br/>
                    <h2>
                        Każda lekcja na platformie składa się z:
                    </h2>
                    <br/>
                        <Bullet emoji="🎥">
                            <span><b className="purple">filmu</b> z wieloma animacjami, tłumaczącego teorię oraz zadania</span>
                        </Bullet>
                        <Bullet emoji="📝">
                            <span><b className="purple">zadań otwartych</b> wraz z omówieniami</span>
                        </Bullet>
                        <Bullet emoji="🧠">
                            <span><b className="purple">zadań zamkniętych</b> w formie quizów</span>
                        </Bullet>
                        <Bullet emoji="📒">
                            <span><b className="purple">notatek</b> z ilustracjami</span>
                        </Bullet>
                    <br/>

                    <span>
                        Na sam koniec dostajesz jeszcze <b>interaktywny test końcowy</b> kompleksowo sprawdzający Twoją wiedzę.
                    </span>

                    <div className="course-preview__main-section__left-panel__text-section__learning-method__laptop">
                        <img  className="laptop" alt="" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/laptops/laptop-test-koncowy.jpg`}></img>
                    </div>

                    <h2>
                        Zakup kursu nie wiąże się z żadnym ryzykiem
                    </h2>
                 
                    <Guarantee authContext={authContext} component="course preview"/>

                    <h2>
                        Nie jesteś sam! 
                    </h2>
                    <div className="course-preview__main-section__left-panel__text-section__consultations">
                        <span className="course-preview__main-section__left-panel__text-section__consultations__main-text">
                            Pozostajesz <b>pod stałą opieką</b> twórców kursu. 
                        </span>
                        <br/>
                        <span className="course-preview__main-section__left-panel__text-section__consultations__second-text">
                            Jeśli masz problem z zadaniem, śmiało napisz do nas z pytaniem ✏️. Odpowiadamy na pytania raz w tygodniu.
                        </span>
                        
                    </div>
                    <br/>
                    <h2>
                        Spis treści:
                    </h2>
                    <TableOfContents 
                        courseContent={props.courseContent} 
                        publiclyAvailableChapter={props.publiclyAvailableChapter} 
                        publiclyAvailableLessons={props.publiclyAvailableLessons}
                        setTableOfContentsRef={props.setTableOfContentsRef}
                        fromLanding={props.fromLanding}
                    />
                </div>

                <div 
                    ref={aboutAuthorsRef}
                    className="course-preview__main-section__left-panel__text-section__about-author">
                    <h2>
                        O autorach:
                    </h2>
                   {
                        AuthorsData.map((el, index) => {
                            if(el.myCourses?.some(el => el  === props.courseName) )
                                return <Authors
                                    key={index}
                                    imgSrc={el.imgSrc}
                                    name={el.name}
                                    description={el.description}
                                    bulletPoints={el.bulletPoints}
                                />
                            else return <></>
                        })
                   }
                </div>                

                {
                    props.fromLanding &&              
                    <div 
                        className="course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator--bottom">

                    </div> 
                }
            </div>
        </div>
    )
}