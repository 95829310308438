interface BoxProps {
    label: string;
    index: number;
}

const Box = ({ label, index }: BoxProps) => {
    return (
        <div className={`main-page__final-test-information__graphic__test__box ${label}`}>
            <span>{index + 1}</span>
        </div>
    );
};

export const FinalTestPreview = () => {

    const boxesDesktop = [
        "w", "w", "w", "l", "l", "l", "bw",
        "w", "l", "l", "l", "l", "l", "l",
        "l", "l", "l", "l", "b", "y", "l",
        "w", "w", "l", "lw", "l", "l", "l"
    ];

    const boxesMobile = [
        "w", "w", "w",   
        "w", "l", "y", 
        "l", "b", "l", 
        "w", "w", "l", 
    ];

    return (
        <div className="main-page__final-test-information">

            <div className="main-page__final-test-information__graphic">
                <div className="main-page__final-test-information__graphic__pointer">👆</div>
                <div className="main-page__final-test-information__graphic__text">
                    <span className="main-page__final-test-information__graphic__text__spacer"></span>
                    <span className="main-page__final-test-information__graphic__text__main">
                        Po &nbsp;ukończeniu &nbsp;kursu <br />
                        sprawdź swoją <br />
                        wiedzę &nbsp;w <br />
                        <b>teście końcowym</b>
                    </span>
                </div>

                <div className="main-page__final-test-information__graphic__test desktop">
                    {
                        boxesDesktop.map((label, index) => (
                            <Box label={label} index={index} key={index} />
                        ))
                    }
                </div>
                <div className="main-page__final-test-information__graphic__test mobile">
                    {
                        boxesMobile.map((label, index) => (
                            <Box label={label} index={index} key={index} />
                        ))
                    }
                </div>

            </div>

        </div>
    );
};
