import React, { useState, useEffect, useRef } from "react";
import { CarouselBackground } from "../graphics/CarouselBackground";
import { CarouselElement } from "./CarouselElement/CarouselElement";

import "./carousel.scss";
import { useTranslation } from "react-i18next";

interface NavPointProps {
    index: number,
    activeIndex: number
}

export const NavPoint = (props: NavPointProps) => {
    const { index, activeIndex } = props;

    const handlePointClass = () => {
        const base = "main-page__carousel-section__progress-bar__point";
        return `${base}${( index === activeIndex) ? ` ${base}--enrolled`: ""}`;
    }

    return (
        <div className={handlePointClass()}></div>
    )
}

export const Carousel = () => {
    const { t } = useTranslation();
    
    const PanelsCount = 5;

    const [activeIndex, setActiveIndex] = useState(2);
    const [positionStyle, setPositionStyle] = useState({ left: 'calc( var(--base-step) - 1 * var(--panel-width)  )', transition: 'left 0s ease'});
    const [deactivatedPrevIndex, setDeactivatedPrevIndex] = useState(false);

    const carousel = [
        t("landing.carousel.movingPlatform"),
        t("landing.carousel.exercisesSets"),
        t("landing.carousel.courseDividedIntoChapter"),
        t("landing.carousel.importantNotes"),
        t("landing.carousel.interactiveTests"),
    ];

    const [mutexTimer, setMutexTimer] = useState(false);

    const carouselRef = useRef<HTMLDivElement>(null);
    const elementRefs = useRef<(HTMLDivElement | null)[]>([]); 
    let touchstartX = 0;
    let touchendX = 0;
    const swipeThreshold = 50;

    const getIndex = (change: number) => {
        if (change < 0) {
            return (activeIndex + change < 0) ?
                (PanelsCount) - (Math.abs(activeIndex + change) % (PanelsCount - 1)) :
                activeIndex + change;
        } else {
            return (activeIndex + change > (PanelsCount - 1)) ? 
                activeIndex + change - PanelsCount :
                activeIndex + change;
        }
    }

    const checkDirection = () => {
        const distance = touchendX - touchstartX;
    
        if (Math.abs(distance) > swipeThreshold) {
            if (distance < 0) {
                simulateClick(activeIndex + 1 >= PanelsCount ? 0 : activeIndex + 1); 
            }
            if (distance > 0) {
                simulateClick(activeIndex - 1 < 0 ? PanelsCount - 1 : activeIndex - 1); 
            }
        }
    };

    const simulateClick = (index: number) => {
        if (elementRefs.current[index]) {
            elementRefs.current[index]?.click();
        }
    }

    useEffect(() => {
        const carouselElement = carouselRef.current;

        if (carouselElement) {
            const handleTouchStart = (e: TouchEvent) => {
                touchstartX = e.changedTouches[0].screenX;
            };

            const handleTouchEnd = (e: TouchEvent) => {
                touchendX = e.changedTouches[0].screenX;
                checkDirection();
            };

            carouselElement.addEventListener('touchstart', handleTouchStart);
            carouselElement.addEventListener('touchend', handleTouchEnd);

            return () => {
                carouselElement.removeEventListener('touchstart', handleTouchStart);
                carouselElement.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, []);

    return (
        <>
        <div className="main-page__carousel-intro">
            <div className="main-page__carousel-intro__text-main">
                <span className="main-page__carousel-intro__text-main__main">
                    {t("landing.forSession")}<b>.com</b>
                </span>
                <span className="main-page__carousel-intro__text-main__second">
                    to <b>{t("landing.muchMoreThan")}</b>&nbsp;{t("landing.onlyRecordings")}
                </span>
            </div>    
            <div className="main-page__carousel-intro__text-bottom">
                <span>
                    {t("landing.meetingTheExpectations")}
                </span>
            </div>
        </div>
        <div className="main-page__carousel-section">                
            <div className="main-page__carousel-section__text-section">
                <CarouselBackground/>
            </div>

            <div className="main-page__carousel-section__carousel" ref={carouselRef}>
                <div 
                    className="main-page__carousel-section__carousel__row"
                    style={positionStyle}
                >
                    { 
                        [
                            getIndex(-2),
                            getIndex(-1), 
                            activeIndex, 
                            getIndex(1),
                            getIndex(2),                      
                        ].map((el, index) => (
                            <CarouselElement 
                                key={index} 
                                index={el} 
                                activeIndex={activeIndex} 
                                text={carousel[el]}
                                setActiveIndex={setActiveIndex} 
                                setPositionStyle={setPositionStyle}
                                scrollLeft={index < Math.floor(PanelsCount/2)}
                                setDeactivatedPrevIndex={setDeactivatedPrevIndex}
                                deactivatedPrevIndex={deactivatedPrevIndex}
                                panelCount={PanelsCount}
                                ref={(elRef) => elementRefs.current[index] = elRef}
                                mutexTimer={mutexTimer}
                                setMutexTimer={setMutexTimer} 
                            />
                        ))
                    }     
                </div>
            </div>

            <div className="main-page__carousel-section__progress-bar">
                {
                    Array.from({ length: PanelsCount }, (_, index) => index).map(
                        (el) => <NavPoint key={el} index={el} activeIndex={activeIndex}/>
                    )
                }
            </div>
        </div>
        </>
    )
}
