import { useTranslation } from "react-i18next";

import { Title } from "../../../components/shared/components/titleWithBackButton/title/Title";
import { CourseDescription } from "../../../components/course/courseDescription/CourseDescription";
import { useNavigate } from "react-router-dom";

interface PreviewVideoPageProps {
    src: string;
    lessonDescription: string;
    courseId?: string;
}

export const PreviewVideoPage = (props: PreviewVideoPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const handleBackToCoursePage = () => {
        if(props.courseId) {
            navigate(`/coursePreview/${props.courseId}`);
        } else navigate('/');
    }

    return (
        <>
            <div id="data-panel__title" className="preview-data-panel" onClick={ () => handleBackToCoursePage() }>
                <span data-testid='back-button' className="material-symbols-outlined">
                    arrow_back_ios
                </span>
                <Title title={"Powrót do podglądu kursu"}/>
            </div>

            <div className="responsive-container">
            
                <iframe
                    src={props.src}
                    frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen
                    title="file_example_MP4_1920_18MG">
                </iframe>

            </div>

            <CourseDescription
                title={t("kurs.video.shortLessonDescription")}
                text={props.lessonDescription}
            />
        </>
    );
}