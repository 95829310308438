import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { GlobalDataContext } from "../../global/globalDataContext/GlobalDataContextProvider";
import { TitleHeader } from "../../shared/components/titleHeader/TitleHeader";
import { TitleWithBackArrowButton } from "../../shared/components/titleWithBackButton/TitleWithBackArrowButton";
import { WideMenu } from "../../shared/components/wideMenu/WideMenu";
import { ContentPanelWrapper } from "../../shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { DataPanelWrapper } from "../../shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { popLastElementFromUrl } from "../../shared/utilities/url/url";
import { AssignmentMain } from "./assignmentMain/AssignmentMain";
import { CourseMain } from "./courseMain/CourseMain";
import { CourseMainNavigation } from "./courseMain/courseMainNavigation/CourseMainNavigation";
import { MainContext } from "./MainContextProvider";
import { MainNavigation } from "./mainNavigation/MainNavigation";
import { MainPath } from "./mainPath/MainPath";
import { NotesMain } from "./notesMain/NotesMain";
import { CourseContextProvider } from "../CourseContextProvider";
import { QuizMain } from "./quizMain/QuizMain";
import { QuizContext, QuizContextProvider } from "../additions/quiz/QuizContextProvider";
import { NotFound } from "../../error/NotFound";
import { MainTest } from "../mainTest/MainTest";
import { MainTestPreview } from "../mainTest/MainTestPreview/MainTestPreview";

export const Main = () => {
    const { t } = useTranslation();
    const globalCtx = useContext(GlobalDataContext);
    const mainCtx = useContext(MainContext);
    const [title, setTitle] = useState("");

    const getTranslationForAddition = () => {
        const activeComponent = mainCtx.activeComponent;

        if(activeComponent === 'notes') {
            return t("kurs.glowna.notatki.notatki")
        } else if(activeComponent === 'assignment') {
            return t("kurs.glowna.zadaniaOtwarte") 
        } else if(activeComponent === 'quiz') {
            return t("kurs.glowna.zbiorTestow") 
        } else {
            return t("kurs.glowna.przegladKursu")
        }
    }

    useEffect(() => {
        globalCtx.setIsMainView(true);
    }, [])

    useEffect(() => {
        switch(mainCtx.activeComponent) {
            case "notes":
                setTitle(` - ${getTranslationForAddition()}`)
                break
            case "assignment":
                setTitle(` - ${getTranslationForAddition()}`)
                break
            case "quiz":
                setTitle(` - ${getTranslationForAddition()}`)
                break
            default:
                setTitle("")
                break
        }
    }, [mainCtx.activeComponent])


    const getBackButtonUrl = () => {

        if(mainCtx.activeComponent === 'course') return '/userPanel';
        
        if(mainCtx.activeComponent === 'mainTest') return popLastElementFromUrl();

        return popLastElementFromUrl(popLastElementFromUrl());     
        
    }


    const getNavigationPanel = () => {
        if(mainCtx.activeComponent === 'mainTest') return <></>;

        return mainCtx.activeComponent === 'course' ? <CourseMainNavigation/> : <MainNavigation getTranslationForAddition={getTranslationForAddition}/>
    }

    return (
        <>
            <TitleHeader title={`${globalCtx.title}`}/>
            <ContentPanelWrapper>
                <WideMenu mainCoursePanel={globalCtx.isMainView}/>
                <DataPanelWrapper>
                    <MainPath isGrayedOut={mainCtx.activeComponent === "course"}/>
                    {mainCtx.activeComponent !== 'mainTest' && <TitleWithBackArrowButton navigateTo={getBackButtonUrl()} title={t("kurs.nawigacja.backToAllCourses")}/>}
                    <Routes>
                        <Route path="" element={<CourseMain/>}/>
                        <Route path=":courseChapterId/notes" element={<CourseContextProvider><NotesMain/></CourseContextProvider>}/>
                        <Route path=":courseChapterId/assignment" element={
                            <CourseContextProvider>
                                <AssignmentMain/>
                            </CourseContextProvider>}/>
                        <Route path=":courseChapterId/quiz" element={
                            <CourseContextProvider>
                                <QuizContextProvider>
                                    <QuizMain/>
                                </QuizContextProvider>
                            </CourseContextProvider>}/>
                        <Route path="test/preview" element={<MainTestPreview />} />
                        <Route path="test" element={<MainTest />} />
                        <Route path="" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </DataPanelWrapper>
                {getNavigationPanel()}
            </ContentPanelWrapper>
        </>
    );
}