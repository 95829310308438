import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Unauthorized } from "../../../utilities/rest/errors";
import { post } from "../../../utilities/rest/apiClient";

import ReactGA from "react-ga4";
import { registerEvent } from "../../../hooks/analytics/registerEvent";

interface isLoggedInDto {
    loggedIn: boolean;
}

interface AuthContextProviderProps {
    children: ReactNode;
}

export interface AllowedCookiesInterface {
    fromBrowser: boolean;
    statCookies: boolean;
    prefCookies: boolean;
}

export interface AuthContextProps {
    handleAuthErrors: (error: unknown) => void,

    alertDisplayed: boolean,
    alertMessage: string,
    alertHeadline: string,

    setAlertDisplayed: React.Dispatch<React.SetStateAction<boolean>>,
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
    setAlertHeadline: React.Dispatch<React.SetStateAction<string>>,

    loggedIn: boolean,
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,

    isLoading: boolean,

    allowedCookies: AllowedCookiesInterface,
    setAllowedCookies: React.Dispatch<React.SetStateAction<AllowedCookiesInterface>>,

    newUser: boolean
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider = (props: AuthContextProviderProps) => {
    const navigate = useNavigate();

    const [alertDisplayed, setAlertDisplayed] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertHeadline, setAlertHeadline] = useState("");

    const [loggedIn, setLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [newUser, setNewUser] = useState(false);

    const [allowedCookies, setAllowedCookies] = useState<AllowedCookiesInterface>({
        fromBrowser: false,
        statCookies: false,
        prefCookies: false
    });


    function getCookie(name: string): string | null {
        const nameEQ = `${name}=`;
        const cookiesArray = document.cookie.split(';');
      
        for (let cookie of cookiesArray) {
          cookie = cookie.trim();
          if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
          }
        }
        return null;
    }

    useEffect(() => {
        (async ()=> {
            try {
              const data = await post<isLoggedInDto, any>("users/checkIfUserIsLoggedIn", null);
            
              if(data.loggedIn !== undefined) {
                setLoggedIn(data.loggedIn);
                setIsLoading(false);
              }
                
            } catch(error) {
              //TODO other errors
            }
          })()
    }, []);

    useEffect(() => {

        if(allowedCookies.fromBrowser && allowedCookies.statCookies) {
            
            ReactGA.initialize("G-CMENY1P3C9");

            if(newUser) {
                ReactGA.event({
                    category: 'Cookies accepted by new user',
                    action: 'Cookies accepted by new user',
                    label: 'Cookie accepted by new user',
                });
            }
        }

    }, [allowedCookies.fromBrowser, allowedCookies.statCookies, newUser]);
    

    useEffect(() => {

        const cookieValue = getCookie('cookie-memo');
        if (cookieValue) {
            try {
                const parsedAllowedCookies = JSON.parse(decodeURIComponent(cookieValue)) as AllowedCookiesInterface;
                setAllowedCookies(parsedAllowedCookies);
            } catch (error) {
                //TODO: check for errors
            }
        } else {
            setNewUser(true);
        }
    }, []);


    const handleAuthErrors = (error: unknown) => {
        if(error instanceof Error) {
            if(error instanceof Unauthorized){
                navigate("/login");
            }
        }
    }

    return (

        <AuthContext.Provider value={{
                handleAuthErrors,
                alertDisplayed,
                alertMessage,
                alertHeadline,
                setAlertDisplayed,
                setAlertHeadline,
                setAlertMessage,
                loggedIn,
                setLoggedIn,
                isLoading,
                allowedCookies,
                setAllowedCookies,
                newUser
                
            }}>
            {props.children}
        </AuthContext.Provider>
    )
}