// import { Block, BlockType } from "../../../../../server/shared/block"; //tego nie usuwać
import { Block, BlockType } from "../../../../../../shared/block";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useTranslation } from "react-i18next";
import "./block.scss"
import Latex from "react-latex";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import remarkDirective from 'remark-directive'
import { Dispatch, SetStateAction, useEffect } from "react";
import { useState } from "react";
import { getImageUrl } from "./getImageUrl";
import { visit } from 'unist-util-visit';

interface BlockProps {
    content: string;
    prefix: string | null;
    type?: BlockType;
}

type textColor = "blue" | "yellow" | "red" | "green" | "purple";
type SingleTextChunkType = "text" | "strong" | textColor;

interface SingleTextChunk {
    text: string;
    type: SingleTextChunkType;
}
interface TextBlockProps {
    content: SingleTextChunk[];
    prefix: string | null;
}


const unfoldMacros = (text: string) => {
    
    let processedText = text.replace(/&lt;/g, "<");
    processedText = processedText.replace(/&&SLASH&&/g, '\\');
    processedText = processedText.replace(/&&UNDSC&&/g, '_');

    return processedText;
}

const TextBlock = (props: TextBlockProps) => {
    const className = props.prefix ? `${props.prefix}__text` : "";
    return (
        <div className={className}>
            {props.content.map(el => {

                el.text = unfoldMacros(el.text);
                // console.log(el.text);

                if(el.type === "text"){
                    return <Latex>{el.text}</Latex>
                } else if(el.type === "strong"){
                    return <strong><Latex>{el.text}</Latex></strong>
                } else if(el.type === "red"){
                    return <span style={{color: "red"}}><Latex>{el.text}</Latex></span>
                } else if(el.type === "blue"){
                    return <span style={{color: "#44cadb"}}><Latex>{el.text}</Latex></span>
                } else if(el.type === "purple"){
                    return <span style={{color: "#db529d"}}><Latex>{el.text}</Latex></span>
                }
                
            })}

        </div>
    );
}

const BreakeBlock = () => <br/>;

interface ImageBlockProps extends BlockProps {
    getImageUrl(url: string): Promise<string>;
}

export const ImageBlock = (props: ImageBlockProps) => {
    const [url, setUrl] = useState("");

    useEffect(() => {
        (async () => {
            try{
                const url = await props.getImageUrl(props.content);
                setUrl(url)
            }catch {
                console.log("url not loaded")
            }
           
        })();
    }, [props.content]) 

    return (
        <div className={`${props.prefix}__img-wrapper img-wrapper`}>
            <img alt="" src={url} />
        </div>
    )
}

interface HeaderBlock extends BlockProps {
    content: string;
    level: number;
}
const HeaderBlock = (props: HeaderBlock) => {
    switch(props.level){
        case 1:
            return <h1 className={props.prefix ? props.prefix : ''}>{props.content}</h1>

        case 2:
            return <h2 className={props.prefix ? props.prefix : ''}>{props.content}</h2>

        case 3:
            return <h3 className={props.prefix ? props.prefix : ''}>{props.content}</h3>

        default:
            return <h4 className={props.prefix ? props.prefix : ''}>{props.content}</h4>
    }
}

interface UnorderedListProps {
    listElements: any[];
}

const UnorderedListBLock = (props: UnorderedListProps) => {
    if(!props.listElements) return <></>

    return <ul>
        {props.listElements.map(el => {

            if(el.children ){
                if(el.children[0]){
                    return <li><Latex>{unfoldMacros(el.children[0].value)}</Latex></li>
                }
            }
        })}
    </ul>
}

const OrderedListBlock = (props: UnorderedListProps) => {
    if(!props.listElements) return <></>

    return <ol>
        {props.listElements.map(el => {
            if(el.children ){
                if(el.children[0]){
                    return <li><Latex>{unfoldMacros(el.children[0].value)}</Latex></li>
                }
            }
        })}
    </ol>
}

interface StrongBlockProps {
    content: string;
}
const StrongBlock = (props: StrongBlockProps) => {
    return <strong>{props.content}</strong>
}

export const redDirective = () => {
    const color: textColor = "red";
        return (tree: any) => {
            visit(tree, (node: any) => {
                if (node.type === 'textDirective' && node.name === color) {
                    node.type = 'element';
                    node.tagName = color;
                    node.data = {
                        hName: color,
                        hProperties: {
                            id: node.attributes?.id || undefined,
                            className: node.attributes?.class || undefined,
                            'data-info': node.attributes?.['data-info'] || undefined,
                        },
                        hChildren: node.children || [],
                    };
                }
            });
        };
}

export const blueDirective = () => {
    const color: textColor = "blue";
        return (tree: any) => {
            visit(tree, (node: any) => {
                if (node.type === 'textDirective' && node.name === color) {
                    node.type = 'element';
                    node.tagName = color;
                    node.data = {
                        hName: color,
                        hProperties: {
                            id: node.attributes?.id || undefined,
                            className: node.attributes?.class || undefined,
                            'data-info': node.attributes?.['data-info'] || undefined,
                        },
                        hChildren: node.children || [],
                    };
                }
            });
        };
}

export const purpleDirective = () => {
    const color: textColor = "purple";
        return (tree: any) => {
            visit(tree, (node: any) => {
                if (node.type === 'textDirective' && node.name === color) {
                    node.type = 'element';
                    node.tagName = color;
                    node.data = {
                        hName: color,
                        hProperties: {
                            id: node.attributes?.id || undefined,
                            className: node.attributes?.class || undefined,
                            'data-info': node.attributes?.['data-info'] || undefined,
                        },
                        hChildren: node.children || [],
                    };
                }
            });
        };
}

const preprocessForNewlines = (content: string) => {
    const bigBreak = '\n&&BREAK&&\n';
    const smallBreak = '\n&&break&&\n';

    let preprocessedContent = content.replace(/\n\s*\n/g, `\n${bigBreak}\n`);

    preprocessedContent = preprocessedContent.replace(/\\\s*$/gm, `\n${smallBreak}\n`);
    return preprocessedContent;
}

const preprocessAsteriskInsideLatex = (content: string) => {
    const preprocessedContent = content.replace(/\$(.*?)\$/g, (match) => {
        const replacedMatch = match.replace(/\*/g, '\\*');
        return replacedMatch;
    });

    return preprocessedContent;
}

const preprocessUnderscoreInLatex = (content: string): string => {
    const preprocessedContent = content.replace(/\$(.*?)\$/g, (match) => {
        const replacedMatch = match.replace(/_/g, '&&UNDSC&&');
        return replacedMatch;
    });

    return preprocessedContent;
}

const preprocessBackslashInLatex = (content: string): string => {
    const preprocessedContent = content.replace(/\$(.*?)\$/gs, (match) => {
        const replacedMatch = match.replace(/\\/g, '&&SLASH&&');
        return replacedMatch;
    });

    return preprocessedContent;
}

export const MarkdownBlock = ({prefix, content}: BlockProps) => {
    let preprocessedContent = preprocessForNewlines(content); 
    preprocessedContent = preprocessBackslashInLatex(preprocessedContent);
    preprocessedContent = preprocessUnderscoreInLatex(preprocessedContent);
    preprocessedContent = preprocessAsteriskInsideLatex(preprocessedContent);

    const getText = (node: any) => {
        if (node.children && node.children.length > 0) {
            return node.children.map((child: any) => child.value || '').join('');
        }
        return '';
    };

    const getChildrenForText = (node: any): SingleTextChunk[] => {
        if(node.children && node.children.length > 0){
            const result: SingleTextChunk[] = [];

            for(let child of node.children){
                if(child.type === "text"){
                    result.push({
                        type: "text",
                        text: child.value
                    })
                } else if(child.type === "element"){
                    const getGrandchildrenText = (type: SingleTextChunkType, child: any) => {
                        const elementContent: string[] = [];
                        for(let grandChild of child.children){
                            elementContent.push(grandChild.value)
                        }

                        return {
                            type: type,
                            text: elementContent.join(" ")
                        }
                    }
                    if(child.tagName === "strong") {
                        result.push(getGrandchildrenText("strong", child))
                    } else if(child.tagName === "red") {
                        result.push(getGrandchildrenText("red", child))
                    } else if(child.tagName === "blue") {
                        result.push(getGrandchildrenText("blue", child))
                    } else if(child.tagName === "purple") {
                        result.push(getGrandchildrenText("purple", child))
                    }
                }
                
            }
            return result;
        }
        return [{text: "", type: "text"}] as SingleTextChunk[];
    }

    return <ReactMarkdown 
        children={preprocessedContent} 
        remarkPlugins={
            [
                remarkGfm, remarkDirective,
                redDirective, blueDirective, purpleDirective
            ]} 
        components={{
        h1: ({node, ...attrs}) => <HeaderBlock prefix={prefix} content={getText(node)} level={1} />,
        h2: ({node, ...attrs}) => <HeaderBlock prefix={prefix} content={getText(node)} level={2} />,
        h3: ({node, ...attrs}) => <HeaderBlock prefix={prefix} content={getText(node)} level={3} />,
        h4: ({node, ...attrs}) => <HeaderBlock prefix={prefix} content={getText(node)} level={4} />,
        h5: ({node, ...attrs}) => <HeaderBlock prefix={prefix} content={getText(node)} level={5} />,

        p: ({node, ...props}) => {
            if(!node) return <></>
            let isImage = false;
            let firstChild = '' as any;

            if (node.children && node.children.length === 1){
                firstChild = node.children[0];
                const tagName = firstChild.tagName;

                if(tagName === "img"){
                    isImage = true;
                }
            }
            
            if(isImage){
                return <ImageBlock content={firstChild.properties.src} prefix={prefix} getImageUrl={getImageUrl}></ImageBlock>
            }

            let nodeAnonym = node as any;
            if(nodeAnonym?.children && nodeAnonym?.children[0].value === "&&BREAK&&") { 
                return <div className="line-breaks"></div>;
            }

            if(nodeAnonym?.children && nodeAnonym?.children[0].value === "&&break&&") { 
                return <div className="line-breaks line-breaks--small"></div>;
            }

            return <TextBlock  content={getChildrenForText(node)} prefix={prefix}/>
        },

        code: ({node, className, ...attrs}) => {
            return <CodeBlock type={className ? (className.replace("language-", "")) as BlockType : 'c++'} content={getText(node)} prefix={prefix}></CodeBlock>
        },

        ul: ({node, ...attrs}) => {
            if(!node) return <></>
            return <UnorderedListBLock listElements={node.children}/>
        },

        ol: ({node, ...attrs}) => {
            if(!node) return <></>
            return <OrderedListBlock listElements={node.children}/>
        }

    }}/>
};

const CodeBlock = (props: BlockProps) => {
    const className = props.prefix ? `${props.prefix}__text` : "";
    const { t } = useTranslation();

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
};



    return (
        <div className={className + " " + "block__code"}>
            <div className="block__code__button-wrapper">
                <div onClick={() => copyToClipboard(props.content)} className="block__code__button">{t("code.copy")}</div>
            </div>
            <div className="block__code__button-wrapper">
                <div className="block__code__programming-language">{props.type}</div>
            </div>
            <SyntaxHighlighter language={props.type} style={codeStyle}
                customStyle={{marginTop: '0px', marginBottom: '0px', borderRadius: '10px', padding: '25px'}}
            >
                {"\n\n\n" + props.content}
            </SyntaxHighlighter>
        </div>
    );
}

export const combineBlocks = (blocks: Block[], classPrefix: string | null = "data-panel__notes__container__data") => {
    const result = new Array<JSX.Element>();
        
    let i = 0;
    for(let block of blocks) {

        switch(block.bType) {
            case 'text':
                result.push(<TextBlock key={i++} content={[{text: block.content, type: "text"}]} prefix={classPrefix}/>);
                break;
            case 'image':
                result.push(<ImageBlock key={i++} content={block.content} prefix={classPrefix} getImageUrl={getImageUrl}/>);
                break;
            case 'python': case 'c++': case 'java': case 'c#': case 'c': case 'javascript':
                result.push(<CodeBlock key={i++} type={block.bType} content={block.content} prefix={classPrefix}/>)
                break;
            case 'markdown':
                
                result.push(<MarkdownBlock key={i++} type={block.bType} content={block.content} prefix={classPrefix}/>)
                break;
            case 'break':
                result.push(<BreakeBlock key={i++}/>);
                break;
        }
    }

    return result;
}