import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizContext } from "../additions/quiz/QuizContextProvider";
import { CourseContext } from "../CourseContextProvider";
import { CourseAddition } from "./courseAddition/CourseAddition";
import { CourseVideoChapter } from "./courseChapter/CourseChapter";
import { get } from "../../../utilities/rest/apiClient";
import { useParams } from "react-router-dom";
import { AssignmentCompletionDto } from "../../../../../shared/assignmentCompletionDto"
import { NotesCompletionDto } from "../../../../../shared/notesCompletionDto";
import { GlobalDataContext } from "../../global/globalDataContext/GlobalDataContextProvider";
import { MainContext } from "../main/MainContextProvider";
import i18n from "../../../i18n";

export const CourseNavigation = () => {
    const { t } = useTranslation();
    const ctx = useContext(CourseContext);
    const quizCtx = useContext(QuizContext);
    const [assignmentCompletionDto, setAssignmentCompletionDto] = useState<AssignmentCompletionDto>({} as AssignmentCompletionDto);
    const [notesCompletionDto, setNotesCompletionDto] = useState<NotesCompletionDto>({} as NotesCompletionDto);
    const { courseId } = useParams();
    const globalCtx = useContext(GlobalDataContext);
    const mainCtx = useContext(MainContext);

    useEffect(() => {
        (async ()=> {
            try {
              const data = await get<AssignmentCompletionDto>(`reactRoutes/getAssignmentCompletion/${courseId}/${ctx.getLessonId()}/${ctx.activeChapter}`);
              setAssignmentCompletionDto(data);

            } catch(error) {
              //TODO other errors
              console.log(error)
            }
        })()
    }, [ctx.activeChapter, ctx.currentAssignment])

    useEffect(() => {
        (async ()=> {
            try {
              const lessonIndex = globalCtx.isMainView ? mainCtx.active.lessonIndex : ctx.activeChapter;
              const data = await get<NotesCompletionDto>(`reactRoutes/getNotesCompletion/${courseId}/${ctx.getLessonId()}/${lessonIndex}/${i18n.language}`);
              setNotesCompletionDto(data);

            } catch(error) {
              //TODO other errors
              console.log(error)
            }
        })()
    }, [ctx.activeChapter, ctx.notesEverVisited])


    const handleQuiz = () => {
        if( ctx.chapters.lessons[ctx.activeChapter].quickTestsCount < quizCtx.currentQuiz+1 ) {
            return ctx.chapters.lessons[ctx.activeChapter].quickTestsCount;
        }  

        return (quizCtx.currentQuiz+1);
    }

    return (
        //Nawigacja po rozdziałach
        <div id="navigation-panel" className="navigation-panel__lesson-additions-scalable">
            <div className="chapter chapter--main">
                <div className="chapter__name"> {ctx.path[ctx.path.length-2]} </div>
                <div className="chapter__data-space">
                    {ctx.chapters?.lessons.map((episode, index) => {
                        return <CourseVideoChapter 
                            id={episode.id}
                            key={episode.id}
                            index={index}
                            title={episode.title}
                            stamp={episode.stamp}
                            src={episode.src}
                            />
                    })}
                </div>
            </div>
            <div className="chapter">
                <div className="chapter__name">{t("kurs.nawigacja.materialyDoLekcji")}</div>
                <div className="chapter__data-space">
                    <CourseAddition
                        title={t("kurs.nawigacja.rozwiazTest")}
                        currentStamp={ handleQuiz()}
                        maxStamp={ctx.chapters.lessons[ctx.activeChapter].quickTestsCount}
                        thisAdditionType={'quiz'}
                        testId="quiz-button"
                    />
                    <CourseAddition
                        title={t("kurs.nawigacja.rozwiazZadania")}
                        currentStamp={assignmentCompletionDto.completedOpenTasksIndex + 1}
                        maxStamp={assignmentCompletionDto.totalOpenTaskCount}
                        thisAdditionType={'assignment'}
                        testId="assignment-button"
                    />
                    <CourseAddition
                        title={t("kurs.nawigacja.przegladnijNotatki")}
                        currentStamp={notesCompletionDto.completed ? 1 : 0}
                        maxStamp={notesCompletionDto.notesUploaded}
                        thisAdditionType={'notes'}
                        testId="notes-button"
                        notes={true}
                    />
                </div>
            </div>
        </div>
    );
}